import { Pagination } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react'
import ProductsCard from '../../app/component/card/ProductsCard';
import { sorting } from '../../app/config/enum';
import { useStore } from '../../app/stores/store';

function StoreIndexPage() {

    const { storesStore } = useStore();

    const [ sort, setSort] = useState('newest');
    const [ category, setCategory] = useState('all');

    const itemsPerPage = 6;

    const [currentPage, setCurrentPage] = useState(1);

    // Calculate the starting and ending indexes
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Function to handle page navigation
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };
    
    useEffect(() => {
        storesStore.getListCategories();
        storesStore.getListProducts(category, sort);
    }, [category, sort, storesStore]);

  return (
    <React.Fragment>
        <div className='w-full h-full py-6 px-6 lg:px-40 bg-bg-home bg-no-repeat bg-cover font-sans'>
            <h3 className='text-white font-semibold text-lg pb-2'>Store</h3>
            <hr className='bg-gray-700 w-full h-0.5 border-0'/>
            <div className='w-full text-gray-600 font-semibold text-md flex flex-row justify-start items-center lg:space-x-1'>
                <label htmlFor="sorting" className="hidden lg:block w-auto text-md font-medium text-gray-500 dark:text-white">Sort By : </label>
                <select id="sorting" className="bg-transparent text-secondary border-none text-sm rounded-lg focus:border-transparent w-1/3 lg:w-1/12 p-2.5 mr-4"
                    onChange={(e) => setSort(e.target.value)}
                >
                    <option value={sorting.NEWEST} selected={sorting.NEWEST === sort}>Newest</option>
                    <option value={sorting.OLDEST} selected={sorting.OLDEST === sort}>Oldest</option>
                    <option value={sorting.LOWEST_PRICE} selected={sorting.LOWEST_PRICE === sort}>Price : Low to High</option>
                    <option value={sorting.HIGHEST_PRICE} selected={sorting.HIGHEST_PRICE === sort}>Price : High to Low</option>
                </select>
                <select id="category" className="lg:hidden bg-transparent text-secondary border-none text-sm rounded-lg focus:border-transparent block w-1/3 p-2.5"
                    onChange={(e) => setCategory(e.target.value)}
                >
                    { storesStore.listCategories.map((item, idx) => (
                    <option value={item.id.toString()} selected={item.id.toString() === category} key={idx}>{item.name}</option>
                    ))}
                </select>
            </div>
            <div className='w-full h-full flex flex-row space-x-3'>
                <div className='w-full lg:w-3/4 h-full lg:px-6 py-4 flex flex-col justify-center items-center'>
                    <div className='w-full h-full flex flex-col justify-center items-center'>
                        { storesStore.listProducts.length > 0 ? (
                        <div className='w-full h-full grid grid-cols-1 lg:grid-cols-2 gap-y-4 gap-x-4'>
                            { storesStore.listProducts.slice(startIndex, endIndex).map((item, idx) => (
                                <ProductsCard data={item} key={idx}/>
                            ))}
                        </div>
                        ) : (
                        <div className='w-full h-full text-center lg:pt-10 text-white font-bold text-[5vw] lg:text-[2vw]'>
                            <span>Product Not Found</span>
                        </div>
                        )}
                    </div>
                </div>
                <div className='hidden w-full lg:w-1/4 h-full lg:px-0 py-10 lg:flex flex-col justify-center items-center'>
                    <ul className="menu bg-nav1 w-full rounded-lg text-white font-semibold">
                        <li className="disabled px-4 py-4 bg-[#1D2335]">Filters</li>
                        { storesStore.listCategories.map((item, idx) => (
                            <li className='px-4 py-4 hover:text-secondary cursor-pointer' key={idx} onClick={() => setCategory(item.id.toString())}>{item.name}</li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className='text-center lg:px-6 lg:text-left'>
              <Pagination defaultCurrent={1} current={currentPage} total={storesStore.listProducts.length} pageSize={itemsPerPage} showSizeChanger={false} showLessItems onChange={(value) => handlePageChange(value)}/>
            </div>
        </div>
    </React.Fragment>
  )
}

export default observer(StoreIndexPage)