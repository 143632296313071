import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../config/enum';
import { IMatchesInfo } from '../../models/matches';

interface ScheduleProps {
    data: IMatchesInfo
  }

function MatchCard(props: ScheduleProps) {

    const { data } = props;

    const navigate = useNavigate();

    const [show, setShow] = React.useState(data);

    const handleSelecteSchedule = (id: number) => {
        if(id ===  data.id) {
            setShow(data);
        } else {
            data.another_match?.map(item => {
                if(item.id === id) {
                    return setShow(item);
                } else {
                    return null;
                }
            })
        }
    };

    useEffect(() => {
        setShow(data);
    }, [data]);

  return (
    <div className='bg-[#1C1F2A] lg:w-1/4 h-full rounded-lg'>
        <div className='bg-[#1D2335] px-6 py-2 flex flex-row justify-between items-center rounded-t-lg'>
            <span className='text-white text-[2.8vw] lg:text-[0.9vw] font-medium'>{show.competition.name}</span>
            <select className="py-0 lg:pl-2 lg:py-1 w-[30%] lg:w-[40%] lg:max-w-xs text-gray-500 text-[1.8vw] lg:text-[0.8vw] bg-nav1 border-gray-700 border-1 active:border-none rounded-sm" onChange={(e) => handleSelecteSchedule(Number(e.target.value))}>
                <option className='border-none' value={data.id}>Match 1</option>
                {data.another_match?.map((item, idx) => (
                    <option className='border-none' key={idx} value={item.id}>Match {item.match_day}</option>
                ))}
            </select>
        </div>
        <div className='flex flex-col items-center px-6 py-4 space-y-4 cursor-pointer' onClick={() => navigate(`/league/${data.competition.category.id}/${data.competition.id}/match/${data.id}`)}>
            <div id='home' className='flex flex-row items-center justify-center w-full space-x-2'>
                <div className='lg:h-[3vw] lg:w-[2vw] h-[9vw] w-[8vw] text-start flex flex-row justify-center items-center'>
                    <img src={show.home.flag} alt='home' className='object-contain' />
                </div>
                <div className='flex flex-col justify-center w-2/4 font-medium text-start'>
                    <span className='text-white text-[2.8vw] lg:text-[0.8vw]'>{show.home.name}</span>
                    <span className='text-gray-600 text-[2.5vw] lg:text-[0.6vw]'>{show.homePlayer.name}</span>
                </div>
                <div className='flex flex-row items-center justify-end w-1/4 text-end'>
                    <span className='text-white font-semibold text-[2.8vw] lg:text-[0.8vw] flex flex-row items-center'>{show.result?.home_score ?? ''} <span className='text-[2.5vw] lg:text-[0.7vw] font-normal text-gray-600 ml-1'> {(show.result?.penalty_home !== 0 && show.result?.penalty_home) && '(' + show.result?.penalty_home + ')'}</span></span>
                </div>
            </div>
            <div id='away' className='flex flex-row items-center justify-center w-full space-x-2'>
                <div className='lg:h-[3vw] lg:w-[2vw] h-[9vw] w-[8vw] text-start flex flex-row justify-center items-center'>
                    <img src={show.away.flag} alt='home' className='object-contain' />
                </div>
                <div className='flex flex-col justify-center w-2/4 font-medium text-start'>
                    <span className='text-white text-[2.8vw] lg:text-[0.8vw]'>{show.away.name}</span>
                    <span className='text-gray-600 text-[2.5vw] lg:text-[0.6vw]'>{show.awayPlayer.name}</span>
                </div>
                <div className='flex flex-row items-center justify-end w-1/4 text-end'>
                    <span className='text-white font-semibold text-[2.8vw] lg:text-[0.8vw] flex flex-row items-center'>{show.result?.away_score ?? ''} <span className='text-[2.5vw] lg:text-[0.7vw] font-normal text-gray-600 ml-1'> {(show.result?.penalty_away !== 0 && show.result?.penalty_away) && '(' + show.result?.penalty_away + ')'}</span></span>
                </div>
            </div>
        </div>
        <div className='px-6 pb-2 -mt-3'>
            <span className='text-gray-500 text-[2.2vw] lg:text-[0.57vw]'>{show.qualification} / {formatDate(show.match_date.toString())} GMT +7/ {show.description}</span>
        </div>
    </div>
  )
}

export default observer(MatchCard)