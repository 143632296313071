import { MenuOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import MenuNav from './MenuNav'
import TopNavbar from '../topNavbar/TopNavbar'
import NavDrawerMobile from './NavDrawerMobile'
import { useStore } from '../../stores/store'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../../config/firebase'

function Navbar() {
    
    const [open, setOpen] = useState<boolean>(false);

    const { accountStore } = useStore();
    return (
    <>  
        <div className='hidden lg:block'>
            <TopNavbar/>
            <NavDrawerMobile open={open} setOpen={setOpen}/>
        </div>
        <div className='flex flex-row items-center justify-between w-full h-20 px-6 lg:px-20 xl:px-40 bg-nav2'>
            <div className='flex flex-row items-center justify-start w-1/2 space-x-4 lg:w-1/3'>
                <div className="lg:hidden">
                    <MenuOutlined className='text-white' style={{fontSize: 24}} onClick={() => setOpen(true)}/>
                </div>
                <a href="/" className='w-full h-full'>
                    <img src="/assets/images/ifel-logo-putih.png" alt="ifel-logo" className='w-[60%] h-[60%] md:w-[30%] md:h-[30%] lg:w-[25%] lg:h-[25%]'/>
                </a>
            </div>
            {accountStore.isLoggedIn ? (
                <div className='lg:hidden'>
                    <TopNavbar/>
                </div>
            ) : (
                <div className='flex flex-row items-center justify-end w-1/2 space-x-2 lg:hidden'>
                <a href='/login' className='text-white text-xs font-semibold border-2 border-white px-6 py-[8px] rounded-md' onClick={() => logEvent(analytics, 'users_click_login')}>Login</a>
                <a href='/register' className='text-white text-xs bg-secondary font-semibold hover:bg-nav2 hover:text-secondary px-6 py-[10px] rounded-md'>Register</a>  
                </div>
            )}
            
            <div className='hidden lg:block w-2/3 text-white lg:text-[0.8vw]'>
                <MenuNav/>
            </div>
        </div>
    </> 
  )
}

export default Navbar