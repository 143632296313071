import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Dashboard from './app/component/layouts/Dashboard';
import { useStore } from './app/stores/store';
import LoginAccount from './features/account/LoginAccount';
import PengeluaranForm from './features/pengeluaran/PengeluaranForm';
import PengeluaranIndexPage from './features/pengeluaran/PengeluaranIndexPage';
import { Spin } from 'antd';
import Home from './features/homepage/Home';
import Layouts from './app/component/layouts/Layouts';
import 'typeface-inter';
import RegisterAccount from './features/account/RegisterAccount';
import ProfileIndexPage from './features/profile/ProfileIndexPage';
import RequiredAuth from './app/utils/RequireAuth';
import ProfileDetail from './features/profile/ProfileDetail';
import ProfileSettingPassword from './features/profile/ProfileSettingPassword';
import ProfileTransactionHistory from './features/profile/ProfileTransactionHistory';
import NewsIndexPage from './features/news/NewsIndexPage';
import NewsGrid from './features/news/NewsGrid';
import NewsDetail from './features/news/NewsDetail';
import ContactPage from './features/contact/ContactPage';
import SponsorshipPage from './features/contact/SponsorshipPage';
import LeagueIndexPage from './features/league/LeagueIndexPage';
import StoreIndexPage from './features/store/StoreIndexPage';
import StoreDetailProductPage from './features/store/StoreDetailProductPage';
import StorePayment from './features/store/StorePayment';
import ProfileTournaments from './features/profile/ProfileTournaments';
import ClubProfile from './features/club/ClubProfile';
import MatchStatistics from './features/match/MatchStatistics';

const router = createBrowserRouter([
  {
    path: "/dashboard",
    element: <Dashboard/>,
    children: [
      {
        path: "pengeluaran",
        element: <PengeluaranIndexPage/>
      },
      {
        path: "pengeluaran/form",
        element: <PengeluaranForm/>
      },
    ]
  },
  {
    path: "login-admin",
    element: <LoginAccount/>
  },
  {
    path: "/",
    element: <Layouts/>,
    children: [
      {
        path: "/",
        element: <Home/>
      },
      {
        path: "/register",
        element: <RegisterAccount/>
      },
      {
        path: "/login",
        element: <LoginAccount/>
      },
      //profile
      {
        path: "/profile",
        element: <RequiredAuth><ProfileIndexPage><ProfileDetail/></ProfileIndexPage></RequiredAuth>,
      },
      {
        path: "/setting-password",
        element: <RequiredAuth><ProfileIndexPage><ProfileSettingPassword/></ProfileIndexPage></RequiredAuth>,
      },
      {
        path: "/transaction-history",
        element: <RequiredAuth><ProfileIndexPage><ProfileTransactionHistory/></ProfileIndexPage></RequiredAuth>,
      },
      {
        path: "/profile-tournament",
        element: <RequiredAuth><ProfileIndexPage><ProfileTournaments/></ProfileIndexPage></RequiredAuth>,
      },
      //news
      {
        path: "/news",
        element: <NewsIndexPage><NewsGrid/></NewsIndexPage>
      },
      {
        path:"/news/:slug",
        element:<NewsIndexPage><NewsDetail/></NewsIndexPage>
      },
      //contact
      {
        path: "/contacts",
        element: <ContactPage/>
      },
      {
        path:"/sponsorship",
        element:<SponsorshipPage/>
      },
      //league
      {
        path: "/league/:categoryLeague",
        element: <LeagueIndexPage/>
      },
      {
        path: "/league/:categoryLeague/:leagueId",
        element: <LeagueIndexPage/>
      },
      //store
      {
        path: "/store",
        element: <StoreIndexPage/>
      },
      {
        path: "/store/:productId",
        element: <RequiredAuth><StoreDetailProductPage/></RequiredAuth>
      },
      {
        path: "/store/:productId/:transactionId/payment",
        element: <StorePayment/>
      },
      //club
      {
        path:"/league/:categoryLeague/:leagueId/club/:clubId",
        element: <ClubProfile/>
      },
      //match
      {
        path:"/league/:categoryLeague/:leagueId/match/:matchId",
        element: <MatchStatistics/>
      }
    ]
  },
]);

function App() {

  const { commonStore, accountStore, leagueStore } = useStore();

    useEffect(() => {
      leagueStore.getCategory();
  }, [leagueStore]);

  useEffect(() => {
    if (commonStore.token) {
      accountStore.getUser().finally(() => commonStore.setAppLoaded());
    } else {
      commonStore.setAppLoaded();
    }
  }, [accountStore, commonStore]);
  
  if (!commonStore.appLoaded) return(
    <div style={{ width: '100wh', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'black' }}>
      <Spin tip="Please wait" size="large">
      </Spin>
  </div>
    
  )

  return (
    <RouterProvider router={router} />
  )
}

export default observer(App);