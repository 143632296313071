import React, { ChangeEvent, useState } from 'react'

type Props = {
    onChange: (value: string) => void;
    label: string;
    labelColor: string;
    bgColor: string;
    textColor: string;
}

function InputPassword(props : Props) {

const { onChange, label, labelColor, bgColor, textColor } = props;

const [password, setPassword] = useState("");

 const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  function togglePasswordVisibility() {
    setIsPasswordVisible((prevState) => !prevState);
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPassword(value);
    onChange(value);
  };

  return (
    <div className="relative w-full container mx-auto font-sans">
      <input
        type={isPasswordVisible ? "text" : "password"} 
        placeholder='   '
        value={password}
        onChange={(e) => handleChange(e)}
        className={`${bgColor} ${textColor} border-none block px-2.5 pb-1.5 pt-3 w-full text-[2vw] lg:text-[0.8vw] border-nav2 rounded-lg appearance-none dark:text-white peer`}
      />
    <label className={`${labelColor} font-sans absolute text-[2vw] lg:text-[0.8vw] font-semibold dark:text-gray-400 duration-300 transform -translate-y-1 scale-75 top-2 z-10 origin-[0] bg-transparent dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-1 left-1`}>{label}</label>
      <button
        className={`absolute inset-y-0 right-0 flex items-center px-4 ${labelColor}`}
        onClick={togglePasswordVisibility}
      >
        {isPasswordVisible ? (
          <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
            />
          </svg>
        )}
      </button>
    </div>
  );
}

export default InputPassword