// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCP-i_m4-Dwp-Ub26EoUi6bHP4Wzn25P7c",
  authDomain: "indonesian-football-e-league.firebaseapp.com",
  projectId: "indonesian-football-e-league",
  storageBucket: "indonesian-football-e-league.appspot.com",
  messagingSenderId: "519592390721",
  appId: "1:519592390721:web:781e86783ab48b013656c0",
  measurementId: "G-KQNNR739KF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);