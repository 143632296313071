import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { IAccountAddress, IAccountChangePassword, IAccountInfo, IAccountLoginValues, IAccountRegisterRequest, ILoginInfo } from "../models/account";
import { INotification } from "../models/notifications";
import { store } from "./store";

export default class AccountStore {
    account: ILoginInfo | null = null;
    user : IAccountInfo | null = null;
    userAddress : IAccountAddress | null = null;
    notification : INotification[] = [];

    loadingAddressForm = false;
    loadingLogin = false;
    loadingRegister = false;
    loadingChangePassword = false;

    constructor() {
        makeAutoObservable(this);
    }

    get isLoggedIn() {
        return !!this.user;
    }

    login = async (creds: IAccountLoginValues) => {
        try {
            this.loadingLogin = true;
            const user = await agent.Account.login(creds);
            store.commonStore.setToken(user.access_token);
            this.getUser();
            window.location.replace("/");
        } catch (error) {
            throw error;
        } finally {
            runInAction(() => {
                this.loadingLogin = false;
            })
        }
    }

    private setUser = (user: ILoginInfo | null) => {
        this.account = user;
    }

    logout = async () => {
        store.commonStore.setToken(null);
        this.setUser(null);
        this.setUserInfo(null);
        window.location.replace("/login"); 
    }

    getUser = async () => {
        try {
            const user = await agent.Account.me();
            this.setUserInfo(user.data);
        } catch (error) {
            console.log(error);
        }
    }

    private setUserInfo = (user: IAccountInfo | null) => {
        this.user = user;
    }

    register = async (creds: IAccountRegisterRequest) => {
        try {
            this.loadingRegister = true;
            const user = await agent.Account.register(creds);
            store.commonStore.setToken(user.access_token);
            this.setUser(user);
            window.location.replace("/");
        } catch (error) {
            throw error;
        } finally {
            runInAction(() => {
                this.loadingRegister = false;
            })
        }
    }

    //User Adress
    getUserAddress = async () => {
        try {
            const address = await agent.Account.address();
            this.setUserAddress(address.data);
            return address;
        } catch(error) {
            console.log(error);
        }
    }

    setUserAddress = (address: IAccountAddress | null) => {
        this.userAddress = address;
    }

    createUserAddress = async (data: IAccountAddress) => {
        try {
            this.loadingAddressForm = true;
            await agent.Account.createAddress(data);
        } catch (error) {
            throw(error);
        } finally {
            runInAction(() => {
                this.loadingAddressForm = false;
              })
        }
    }

    //Change Password
    changePassword = async (pass: IAccountChangePassword) => {
        try {
            this.loadingChangePassword = true;
            const change = await agent.Account.changePassword(pass);
            store.commonStore.setToken(change.access_token);
        } catch (error) {
            throw(error);
        } finally {
            runInAction(() => {
                this.loadingChangePassword = false;
            })
        }
    }

    getNotifications = async () => {
        try {
            const notifications = await agent.Notification.listNotification();
            this.setNotification(notifications);
        } catch (error) {
            console.log(error);
        }
    }

    setNotification = (data: INotification[]) => {
        this.notification = data;
    }

    readNotifications = async () => {
        try {
            await agent.Notification.readNotification();
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.getNotifications();
            })
        }
    }

}
