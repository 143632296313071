import { observer } from 'mobx-react-lite'
import React from 'react'
import { IPostV2 } from '../../models/post'

interface PopularPostProps {
  post : IPostV2
}

function PopularPost(props: PopularPostProps) {

  const { post } = props;

  return (
    <div className='flex flex-row items-start w-full mt-4 space-x-2 font-sans'>
        <div className='h-16 overflow-hidden w-28'>
            <img src={post.featured_img ? post.featured_img : 'assets/loading.svg'} alt="popular-post" className='object-cover w-full h-full transition duration-500 ease-in-out transform rounded-md hover:scale-125' />
        </div>
        <div className='flex flex-col w-full h-full'>
            <a className='text-xs font-bold text-white hover:text-secondary' href={`/news/${post.slug}`}>{post.title.rendered}</a>
            <span className='text-gray-500 text-[10px] pt-2 font-medium'>{new Date(post.date_gmt).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" })}</span>
        </div>
    </div>
  )
}

export default observer(PopularPost)