import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useStore } from '../../app/stores/store'
import { IAccountAddress } from '../../app/models/account';
import './profile.css';

function ProfileDetail() {

    const { accountStore } = useStore();

    const collapse = true;

    const [valueAddress, setValueAddress] = useState<IAccountAddress | null>({});

    useEffect(() => {
        accountStore.getUserAddress().then((data) => setValueAddress(data?.data ?? null));
    }, [accountStore]);

    const handleInputName = (value: string) => {
        setValueAddress(prevState => ({...prevState, name: value}));
    }

    const handleInputAddress = (value: string) => {
        setValueAddress(prevState => ({...prevState, address: value}));
    }

    const handleInputPostalCode = (value: string) => {
        setValueAddress(prevState => ({...prevState, postal_code: value}));
    }

    const handleInputPhone = (value: string) => {
        setValueAddress(prevState => ({...prevState, phone: value}));
    }

    const handleInputState = (value: string) => {
        setValueAddress(prevState => ({...prevState, state: value}));
    }

    const handleSubmit = () => {
        if(valueAddress) {
            accountStore.createUserAddress(valueAddress).then(() => alert("berhasil menambahkan / update data"));
        }
    };

  return (
    <div className='w-full bg-nav2 rounded-lg flex flex-row justify-center items-center py-[8vh] font-sans px-4 lg:px-0'>
        <div className='w-full lg:w-3/4 bg-nav1 flex flex-col space-y-6 items-center justify-center rounded-lg py-[3vh]'>
            {/* <div className="avatar">
                <div className="w-36 rounded-full">
                    <img src={accountStore.user?.avatar} alt='avatar' />
                </div>
            </div> */}
            <div className='w-2/3 lg:w-1/3 h-full bg-nav2 flex flex-col justify-center items-start text-gray-600 p-2'>
                <span className='lg:text-[0.6vw]'>Username</span>
                <span className='lg:text-[0.8vw]'>{accountStore.user?.name}</span>
            </div>
            <div className='w-full h-full px-6 flex flex-col'>
                <div className='w-full h-full flex flex-row items-center justify-start text-secondary font-semibold'>
                    <span className='text-[2.2vw] lg:text-[0.8vw] cursor-pointer'>Settings your data info for shipment</span>
                    {/* {collapse ? 
                    (<svg xmlns="http://www.w3.org/2000/svg" transform="rotate(180 0 0)" style={{transform: 'rotate(180deg)'}} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="3" className="w-4 h-4 ml-1">
                        <path d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>) : 
                    (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="3" className="w-4 h-4 ml-1">
                    <path d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>)
                    } */}
                </div>
                {collapse ?
                <div className='w-full h-full flex flex-col mt-4 space-y-2'>   
                    <div>
                        <div className="relative w-full">
                            <input type="text" id="small_outlined" className="block px-2.5 pb-1.5 pt-5 w-full text-xs border-nav2 text-white bg-nav2 rounded-lg appearance-none dark:text-white peer" placeholder=" " value={valueAddress?.name} onChange={(e) => handleInputName(e.target.value)} />
                            <label htmlFor="small_outlined" className="absolute text-[2.2vw] lg:text-[0.8vw] text-gray-700 font-semibold dark:text-gray-400 duration-300 transform -translate-y-1 scale-75 top-2 z-10 origin-[0] bg-nav2 dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-1 left-1">Full Name</label>
                        </div>    
                        {(valueAddress?.name === null || valueAddress?.name === '') && <p id="outlined_error_help" className="ml-2 mt-2 text-[0.6vw] text-right text-red-600 dark:text-red-400"><span className="font-medium">Nama</span> tidak boleh kosong!</p> }
                    </div>
                    <div>
                        <div className="relative">
                            <input type="text" id="small_outlined" className="block px-2.5 pb-1.5 pt-5 w-full text-xs border-nav2 text-white bg-nav2 rounded-lg appearance-none dark:text-white peer" placeholder=" " value={valueAddress?.address} onChange={(e) => handleInputAddress(e.target.value)}/>
                            <label htmlFor="small_outlined" className="absolute text-[2.2vw] lg:text-[0.8vw] text-gray-700 font-semibold dark:text-gray-400 duration-300 transform -translate-y-1 scale-75 top-2 z-10 origin-[0] bg-nav2 dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-1 left-1">Address</label>
                        </div>
                        {(valueAddress?.address === null || valueAddress?.address === '') && <p id="outlined_error_help" className="ml-2 mt-2 text-[0.6vw] text-right text-red-600 dark:text-red-400"><span className="font-medium">Alamat</span> tidak boleh kosong!</p> }
                    </div>
                    <div>
                        <div className="relative">
                            <input type="text" id="small_outlined" className="block px-2.5 pb-1.5 pt-5 w-full text-xs border-nav2 text-white bg-nav2 rounded-lg appearance-none dark:text-white peer" placeholder=" " value={valueAddress?.state} onChange={(e) => handleInputState(e.target.value)}/>
                            <label htmlFor="small_outlined" className="absolute text-[2.2vw] lg:text-[0.8vw] text-gray-700 font-semibold dark:text-gray-400 duration-300 transform -translate-y-1 scale-75 top-2 z-10 origin-[0] bg-nav2 dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-1 left-1">State</label>
                        </div>
                        {(valueAddress?.state === null || valueAddress?.state === '') && <p id="outlined_error_help" className="ml-2 mt-2 text-[0.6vw] text-right text-red-600 dark:text-red-400"><span className="font-medium">State</span> tidak boleh kosong!</p> }
                    </div>
                    <div>
                        <div className="relative">
                            <input type="number" id="small_outlined" className="block px-2.5 pb-1.5 pt-5 w-full text-xs border-nav2 text-white bg-nav2 rounded-lg appearance-none dark:text-white peer" placeholder=" " value={valueAddress?.postal_code} onChange={(e) => handleInputPostalCode(e.target.value)}/>
                            <label htmlFor="small_outlined" className="absolute text-[2.2vw] lg:text-[0.8vw] text-gray-700 font-semibold dark:text-gray-400 duration-300 transform -translate-y-1 scale-75 top-2 z-10 origin-[0] bg-nav2 dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-1 left-1">Postal Code</label>
                        </div>
                        {(valueAddress?.postal_code === null || valueAddress?.postal_code === '') && <p id="outlined_error_help" className="ml-2 mt-2 text-[0.6vw] text-right text-red-600 dark:text-red-400"><span className="font-medium">Kode pos</span> tidak boleh kosong!</p> }
                    </div>
                    <div>
                        <div className="relative">
                            <input type="number" id="small_outlined" className="block px-2.5 pb-1.5 pt-5 w-full text-xs border-nav2 text-white bg-nav2 rounded-lg appearance-none dark:text-white peer" placeholder=" " value={valueAddress?.phone} onChange={(e) => handleInputPhone(e.target.value)}/>
                            <label htmlFor="small_outlined" className="absolute text-[2.2vw] lg:text-[0.8vw] text-gray-700 font-semibold dark:text-gray-400 duration-300 transform -translate-y-1 scale-75 top-2 z-10 origin-[0] bg-nav2 dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-1 left-1">Phone Number</label>
                        </div>
                        {(valueAddress?.phone === null || valueAddress?.phone === '') && <p id="outlined_error_help" className="ml-2 mt-2 text-[0.6vw] text-right text-red-600 dark:text-red-400"><span className="font-medium">Nomor Hp</span> tidak boleh kosong!</p> }
                    </div>
                    <div className='w-full h-full text-center pt-2'>
                        {!accountStore.loadingAddressForm ? 
                            <button onClick={() => handleSubmit()} disabled={!valueAddress?.name || !valueAddress?.address || !valueAddress?.postal_code || valueAddress?.phone === ''} className={`btn btn-sm disabled:bg-gray-600 disabled:cursor-not-allowed disabled:text-gray-700 text-white font-semibold bg-secondary px-3 border-none rounded-md text-[2.2vw] lg:text-[0.8vw] hover:bg-nav2 hover:text-secondary transition duration-300`}>Save Settings</button> : 
                            <button onClick={() => handleSubmit()} disabled={true} className={`loading btn btn-sm disabled:bg-secondary disabled:cursor-not-allowed disabled:text-white text-white font-semibold bg-secondary px-3 border-none rounded-md text-[2.2vw] lg:text-[0.8vw] hover:bg-nav2 hover:text-secondary transition duration-300`}>Please Wait</button>
                        }
                    </div>
                </div> :
                <></>
                }
            </div>
        </div>
    </div>
  )
}

export default observer(ProfileDetail)