import { createContext, useContext } from "react";
import AccountStore from "./accountStore";
import CommonStore from "./commonStore";
import PengeluaranStore from "./pengeluaranStore";
import LeagueStore from "./leagueStore";
import StoresStore from "./StoresStore";
import TransactionStore from "./transactionStore";
import PostStore from "./postStore";
import ClubStore from "./clubStore";

interface Store {
    // Pengeluaran
    pengeluaranStore : PengeluaranStore,
    commonStore : CommonStore,
    accountStore : AccountStore,
    leagueStore : LeagueStore,
    storesStore: StoresStore,
    transactionStore: TransactionStore,
    postStore: PostStore,
    clubStore: ClubStore
}

export const store: Store = {
    pengeluaranStore: new PengeluaranStore(),
    commonStore: new CommonStore(),
    accountStore: new AccountStore(),
    leagueStore: new LeagueStore(),
    storesStore: new StoresStore(),
    transactionStore: new TransactionStore(),
    postStore: new PostStore(),
    clubStore: new ClubStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}