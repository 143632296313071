import React, { useEffect, useState } from 'react'
import Navbar from './Navbar';
import { Content } from 'antd/es/layout/layout';
import { Outlet, useLocation } from 'react-router-dom';
import Footers from './Footers';
import AdsBannerPopup from '../ads/AdsBannerPopup';
import AdsBannerBottom from '../ads/AdsBannerBottom';
import { analytics } from '../../config/firebase';
import { logEvent } from 'firebase/analytics';

const Layouts = () => {
  let location = useLocation();
  
  const pathnames = location.pathname.split("/").filter(x => x);

    const [showPopup, setShowPopup] = useState(false);
    const [showPopupBottom, setShowPopupBottom] = useState(false);

    const noAds = ['login', 'register', 'profile', 'setting-password', 'profile-tournament', 'transaction-history', 'sponsorship', 'contacts'];

    const handleClose: () => void = () => {
      setShowPopup(false);
    };

    const handleCloseBottom: () => void = () => {
      setShowPopupBottom(false);
    };

    useEffect(() => {
      const timer = setTimeout(() => {
        //setShowPopup(true);
      }, 3000);

      return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
      const timer2 = setTimeout(() => {
        //setShowPopupBottom(true);
      }, 7000);

      return () => clearTimeout(timer2);
    }, []);

    useEffect(() => {
      logEvent(analytics, 'page_view');
    }, []);

  return (
    <div className='relative w-full h-full font-sans bg-nav2'>
      <Navbar/>
      <div>
        <Content>
          <Outlet/>
        </Content>
      </div>
      {(pathnames[0] !== 'login' && pathnames[0] !== 'register') && <Footers/> }
      {(!noAds.includes(pathnames[0]) && showPopup) && <AdsBannerPopup onClose={handleClose} />}
      {(!noAds.includes(pathnames[0]) && showPopupBottom) && <AdsBannerBottom onClose={handleCloseBottom} />}
    </div>
  )
}

export default Layouts