import React from 'react'
import { useStore } from '../../app/stores/store'
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

function LeagueStandings() {

    const { leagueStore } = useStore();

    const navigate = useNavigate();

    const isMobile = window.matchMedia('(max-width: 768px)').matches;

  return (
    <React.Fragment>
        <div className='w-full h-full py-4'>
            <div className='block w-full h-full divide-y-2 divide-y-reverse divide-gray-700'>
                <h3 className='mb-2 text-lg font-bold text-white'>Standings</h3>
                <hr/>
            </div>
            <div className='w-full h-full py-4 lg:px-6'>
                <table className="w-full text-white border-separate table-auto border-spacing-y-2">
                    {/* head */}
                    <thead className=''>
                    <tr className='bg-[#1D2335] text-[2.5vw] lg:text-[0.9vw]'>
                        <th className='flex flex-row items-center w-full h-full px-3 py-2 text-start'><img src={leagueStore.selectedLeague?.flag} alt='logo' className='w-4 mr-2 lg:w-4 xl:w-6'/><span>{leagueStore.selectedLeague?.name}</span></th>
                        <th className='text-center'>P</th>
                        <th className='text-center'>W</th>
                        <th className='text-center'>D</th>
                        <th className='text-center'>L</th>
                        <th className='text-center'>GF</th>
                        <th className='text-center'>GA</th>
                        <th className='text-center'>GD</th>
                        <th className='text-center'>PTS</th>
                    </tr>
                    </thead>
                    <tbody>
                    {/* row 1 */}
                    {leagueStore.standings?.map((data, idx) => (
                        <tr className='bg-nav1' key={idx}>
                            <td className='flex flex-row items-center py-2 cursor-pointer' onClick={() => navigate(`club/${data.team.id}`)}>
                                <span className='lg:text-[1vw] font-medium px-3 lg:pl-4 lg:pr-0 lg:w-[2.5vw]'>{idx + 1}</span>
                                <div className='lg:px-4 w-[8vw] h-[7vw] lg:w-[5vw] lg:h-[3vw] flex flex-row justify-center items-center'>
                                    <img src={data.team.flag} alt='logo' className='object-contain w-full h-full' />
                                </div>
                                <span className='pl-4 lg:pl-0 text-[2.8vw] lg:text-[1vw] font-semibold'>{isMobile ? data.team.nickname : data.team.name}</span>
                            </td>
                            <td className='text-center text-[2.5vw] lg:text-[1vw] font-semibold w-[7%]'>{data.match_played}</td>
                            <td className='text-center text-[2.5vw] lg:text-[1vw] font-semibold w-[7%]'>{data.win}</td>
                            <td className='text-center text-[2.5vw] lg:text-[1vw] font-semibold w-[7%]'>{data.draw}</td>
                            <td className='text-center text-[2.5vw] lg:text-[1vw] font-semibold w-[7%]'>{data.lose}</td>
                            <td className='text-center text-[2.5vw] lg:text-[1vw] font-semibold w-[7%]'>{data.goal_for}</td>
                            <td className='text-center text-[2.5vw] lg:text-[1vw] font-semibold w-[7%]'>{data.goal_against}</td>
                            <td className='text-center text-[2.5vw] lg:text-[1vw] font-semibold w-[7%]'>{data.goal_difference}</td>
                            <td className='text-center text-[2.5vw] lg:text-[1vw] font-semibold w-[7%]'>{data.point}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <hr className="h-0.5 bg-gray-700 border-0 dark:bg-gray-700"/>
            <div className='block w-full h-full mt-4 text-center divide-y-2 divide-y-reverse divide-gray-700'>
                <span className='text-white text-center text-[1.2vw]'>
                    {leagueStore.selectedLeague?.qualification_terms}
                </span>
            </div>
            <hr className="h-0.5 mt-4 bg-gray-700 border-0 dark:bg-gray-700"/>
            <div className='w-full h-full pt-4 px-16 flex flex-row justify-center items-center text-gray-500 text-[1.2vw]'>
                <div className='w-1/4 h-full'>
                    <p>
                        <b>P</b> - Matches Played <br/>
                        <b>W</b> - Won
                    </p>
                </div>
                <div className='w-1/4 h-full'>
                    <p>
                        <b>D</b> - Draw <br/>
                        <b>L</b> - Lost
                    </p>
                </div>
                <div className='w-1/4 h-full'>
                    <p>
                        <b>GF</b> - Goal For <br/>
                        <b>GA</b> - Goal Against
                    </p>
                </div>
                <div className='w-1/4 h-full'>
                    <p>
                        <b>GD</b> - Goal Difference <br/>
                        <b>PTS</b> - Points
                    </p>
                </div>
            </div>
        </div>
    </React.Fragment>
  )
}

export default observer(LeagueStandings)