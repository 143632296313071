import { Pagination } from 'antd';
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { formatDate, formatRupiah } from '../../app/config/enum';
import { useStore } from '../../app/stores/store';

function ProfileTransactionHistory() {

    const { transactionStore, accountStore } = useStore();

    const navigate = useNavigate();

    const handlePayment = (order_id: string, airwaybill: string | undefined, transaction_id: number, product_id: number) => {
        navigate(`/store/${product_id}/${transaction_id}/payment`);
    }

    const [ page, setPage] = useState(1);
    
    useEffect(() => {
        transactionStore.listTransactionsByUser(page);
    }, [page, transactionStore]);

  return (
    <div className='w-full bg-nav2 rounded-lg flex flex-col justify-center items-center py-[4vh] font-sans space-y-2'>
        <div className='w-full text-center text-white font-bold'>
            <h3 className='text-[4.5vw] lg:text-[1.5vw]'>Transaction History</h3>
        </div>
        <div className='w-full px-4 lg:px-0 lg:w-[70%] h-full my-4 text-white'>
            {transactionStore.transactionList?.map((trans, idx) => (
            <div className="card card-side bg-nav1 shadow-xl p-2 lg:p-4 mb-2" key={idx}>
                <figure className='border-none rounded-lg w-[38vw] lg:w-[15vw]'><img src={"https://ifel-dash-v2.webuild.id/" + trans.product.image} alt="product"/></figure>
                <div className="card-body m-0 py-0 pl-3 pr-0">
                    <div className='w-full h-auto flex flex-row justify-between items-start'>
                        <span className='text-[2.2vw] lg:text-[0.9vw]'>Status: <span className={`font-bold ${trans.status === 1 ? 'text-red-500' : trans.status === 4 ? 'text-green-500' : 'text-white'}`}>{trans.status === 1 ? 'Unpaid' : trans.status === 4 ? 'Paid' : 'Process'}</span></span>
                        <div className='flex flex-col items-end text-[1.5vw] lg:text-[0.6vw] lg:space-y-2'>
                            <span>Order ID: <span className=''>{trans.order_id}</span></span>
                            <span>Date: {formatDate(trans.created_at)}</span>
                        </div>
                    </div>  
                    <span className='font-semibold text-[2vw] lg:text-[0.9vw]'>{trans.product.name}</span>
                    <div className='w-3/4 h-full flex flex-col items-start text-[1.8vw] lg:text-[0.6vw] font-light space-y-1'>
                        <span className='font-semibold'>Shipping Details</span>
                        <span>{trans.user.name}</span>
                        <span>{trans.user.phone}</span>
                        <span>{accountStore.userAddress?.address}</span>
                    </div>
                    <div className='w-full h-full flex flex-rom items-center justify-center'> 
                        <div className='w-1/2 h-full flex flex-row items-end justify-start'>
                            {trans.status === 1 && (
                                <button className='text-[1.5vw] lg:text-[0.6vw] text-white bg-secondary px-4 py-1 lg:py-2 rounded-md' onClick={() => handlePayment(trans.order_id, trans.airwaybill, trans.id, trans.product_id)}>Pay Now</button>
                            )}
                        </div>
                        <div className='w-1/2 h-full flex flex-col items-end justify-end text-[2vw] lg:text-[0.6vw] font-light space-y-0'>
                            <span>Order Detail</span>
                            <span className='text-[2.2vw] lg:text-[1vw] font-semibold text-secondary'>{formatRupiah(trans.total)}</span>
                        </div>
                    </div>
                </div>
            </div>
            ))}
        </div>
        <div className='text-center col-span-1 lg:col-span-2 lg:text-left'>
            <Pagination defaultCurrent={1} current={page} total={transactionStore.totalTransaction} pageSize={5} showSizeChanger={false} showLessItems onChange={(value) => setPage(value)}/>
        </div>
    </div>
  )
}

export default observer(ProfileTransactionHistory)