import { makeAutoObservable } from "mobx"
import { IPostDetailPayload, IPostV2 } from "../models/post";
import axios from 'axios';

export default class PostStore {

    listPost: IPostV2[] = [];
    listBanner: IPostV2[] = [];
    totalPage = 0;
    pageSize = 6;
    postBySlug: IPostDetailPayload | null = null;
    statusSubscribe = '';
    condSubscribe = false;

    constructor() {
        makeAutoObservable(this);
    }

    getListPost = async (page: number) => {
        try {
            //create axios get api request without agent module (manually)
            await axios.get(`https://admin.ifel.id/wp-json/wp/v2/posts?page=${page}&per_page=${this.pageSize}&categories=1,4&nocache=${new Date().getTime()}`, {
                headers: {"Content-Type": "application/json"}
              })
            .then((response) => {

                this.totalPage = response.headers["x-wp-total"];
                this.setListPost(response.data);

                // eslint-disable-next-line array-callback-return
                this.listPost.map((post: IPostV2) => {
                    axios.get(`https://admin.ifel.id/wp-json/wp/v2/media/${post.featured_media}/`, {
                        headers: {"Content-Type": "application/json"}
                    })
                    .then((response) => {
                       return post.featured_img = response.data.guid.rendered;
                    })
                    .catch((error) => {
                        console.error(error);
                        return error;
                    });
                });
            })
            .catch((error) => {
                console.error(error);
            });
            return this.listPost;
        } catch (error) {
            throw(error)
        }
    }

    getListBanner = async () => {
        try {
            //create axios get api request without agent module (manually)
            await axios.get(`https://admin.ifel.id/wp-json/wp/v2/posts?&categories=1,8`, {
                headers: {"Content-Type": "application/json"}
              })
            .then((response) => {

                this.totalPage = response.headers["x-wp-total"];
                this.setListBanner(response.data);

                // eslint-disable-next-line array-callback-return
                this.listBanner.map((post: IPostV2) => {
                    axios.get(`https://admin.ifel.id/wp-json/wp/v2/media/${post.featured_media}`, {
                        headers: {"Content-Type": "application/json"}
                    })
                    .then((response) => {
                       return post.featured_img = response.data.guid.rendered;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                });
            })
            .catch((error) => {
                console.error(error);
            });
        } catch (error) {
            throw(error)
        }
    }

    setListBanner = (data: IPostV2[]) => {
        this.listBanner = data;
    }

    setListPost = (data: IPostV2[]) => {
        this.listPost = data;
    }

    subscriptions = async (email: string) => {
        try {
            //create axios post api request without agent module (manually)
            await axios.post(`https://admin.ifel.id/wp-json/newsletter/v2/subscriptions`, {email: email} ,{
                headers: {"Content-Type": "application/json"}
              })
            .then((response) => {
                if (response.status === 201) {
                    this.condSubscribe = true;
                    return this.statusSubscribe = 'success';
                } else {
                    this.condSubscribe = true;
                    return this.statusSubscribe = 'error';
                }
            })
            .catch((error) => {
                console.error(error);
            })
        } catch (error) {
            throw(error)
        }
    }

    getPostBySlug = async (slug: string) => {
        try {
            //create axios get api request without agent module (manually)
            await axios.get(`https://admin.ifel.id/wp-json/rae/v1/post?post_slug=${slug}`, {
                headers: {"Content-Type": "application/json"}
              })
            .then((response) => {
                this.setPostBySlug(response.data);
            })
            .catch((error) => {
                console.error(error);
            })
        } catch (error) {
            throw(error)
        }
    }

    setPostBySlug = (data: IPostDetailPayload) => {
        this.postBySlug = data;
    }

    resetStatusSubscribe = () => {
        this.statusSubscribe = '';
        this.condSubscribe = false;
    }
}