import React, { ReactNode } from 'react';
import { useStore } from '../stores/store';
import { Navigate } from 'react-router-dom';

type RequiredAuthProps = {
  children: ReactNode;
};

const RequiredAuth: React.FC<RequiredAuthProps> = ({ children }) => {

    const { accountStore } = useStore()
  if (!accountStore.isLoggedIn) {
    return <Navigate to="/login" replace />
  }
  return <>{children}</>;
};

export default RequiredAuth;