import React, { useEffect, useState } from 'react'
import CarouselHome from '../../app/component/carousel/CarouselHome'
//import MatchCard from '../../app/component/card/MatchCard';
import NewsCard from '../../app/component/card/NewsCard';
//import StandingCard from '../../app/component/card/StandingCard';
import PopularPost from '../../app/component/posts/PopularPost';
import AdsBannerInner from '../../app/component/ads/AdsBannerInner';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useStore } from '../../app/stores/store';
import ClientLogo from '../../app/component/carousel/ClientLogo';
import SocialMediaCard from '../../app/component/card/SocialMediaCard';
import { observer } from 'mobx-react-lite';
import StandingCard from '../../app/component/card/StandingCard';
import LatestMatch from '../match/LatestMatch';
import { IPostV2 } from '../../app/models/post';

const Home = () => {

  const { postStore } = useStore();

  const [showPopupInner, setShowPopupInner] = useState(false);
  const [ post, setPost] = useState<IPostV2[]>([]);

  const handleCloseInner: () => void = () => {
    setShowPopupInner(false);
  };

  useEffect(() => {
      AOS.init();
    }, []);

  useEffect(() => {
    postStore.getListPost(1).then((data) => {
        setPost(data);
    });
  }, [postStore]);

  return (
    <div className='w-full h-full p-0 m-0 font-sans bg-nav1'>
      <div className='w-full h-full bg-bg-home bg-cover bg-no-repeat pb-[20vh]'>
        <CarouselHome/>
        <div className='w-full h-full lg:py-5'>
        {showPopupInner && <AdsBannerInner onClose={handleCloseInner}/>}
        </div>
        {/* Start Fixtures */}
          <LatestMatch/>
        {/* End Fixtures */}

        {/* Start Post Grid */}
        <div className='flex flex-col w-full h-full px-6 lg:px-20 xl:px-40 lg:flex-row lg:space-x-10 lg:mb-12'>
          {/* Latest News */}
          <div className='w-full lg:w-[70%] flex flex-col divide-y-2 divide-gray-700'>
            <h3 className='pb-2 text-lg font-bold text-white'>Latest News</h3>

            <div className='hidden w-full grid-cols-1 gap-4 pt-6 md:grid md:grid-cols-2'>
              {postStore.listPost?.map((item, idx) => {
                if(idx < 4) {
                 return (<NewsCard post={item} key={idx}/>)
                } else {
                  return null;
                }
                  
                })}
              <div className='flex flex-row items-center justify-end w-full space-x-1 lg:justify-start'>
                <a href='/news' className='font-semibold text-[3.8vw] lg:text-[1vw] text-white hover:text-secondary flex flex-row space-x-1 items-center'>
                  <span>More News</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="9" viewBox="0 0 11 9" className='fill-current'>
                  <path d="M0.625024 4.50004C0.625024 4.71553 0.710627 4.92219 0.863 5.07457C1.01537 5.22694 1.22204 5.31254 1.43752 5.31254L7.87252 5.31254L5.73836 7.44671C5.59484 7.60073 5.5167 7.80445 5.52042 8.01494C5.52413 8.22544 5.6094 8.42627 5.75827 8.57513C5.90713 8.724 6.10796 8.80927 6.31846 8.81298C6.52895 8.8167 6.73267 8.73856 6.88669 8.59504L10.4075 5.07421C10.5597 4.92187 10.6451 4.71536 10.6451 4.50004C10.6451 4.28473 10.5597 4.07822 10.4075 3.92588L6.88669 0.405045C6.73267 0.261525 6.52895 0.18339 6.31846 0.187105C6.10797 0.190818 5.90713 0.27609 5.75827 0.424955C5.6094 0.573819 5.52413 0.774653 5.52042 0.985146C5.5167 1.19564 5.59484 1.39935 5.73836 1.55338L7.87252 3.68754L1.43752 3.68754C1.22204 3.68754 1.01537 3.77315 0.863 3.92552C0.710627 4.07789 0.625024 4.28456 0.625024 4.50004Z"/>
                  </svg>
                </a>
              </div>
            </div>
            <div className='grid w-full grid-cols-1 gap-2 pt-6 md:grid-cols-2 md:hidden'>
            {post?.map((item, idx) => {
                if(idx < 1) {
                 return (<NewsCard post={item} key={idx}/>)
                }
                  return null;
                })}
              {postStore.listPost.map((item, idx) => {
                if(idx < 4) {
                 return <PopularPost post={item} key={idx}/>
                }
                  return null;
                })}
              <div className='flex flex-row items-center justify-end w-full space-x-1 lg:justify-start'>
                <a href='/news' className='font-semibold text-[3.8vw] lg:text-[1vw] text-white hover:text-secondary flex flex-row space-x-1 items-center'>
                  <span>More News</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="9" viewBox="0 0 11 9" className='fill-current'>
                  <path d="M0.625024 4.50004C0.625024 4.71553 0.710627 4.92219 0.863 5.07457C1.01537 5.22694 1.22204 5.31254 1.43752 5.31254L7.87252 5.31254L5.73836 7.44671C5.59484 7.60073 5.5167 7.80445 5.52042 8.01494C5.52413 8.22544 5.6094 8.42627 5.75827 8.57513C5.90713 8.724 6.10796 8.80927 6.31846 8.81298C6.52895 8.8167 6.73267 8.73856 6.88669 8.59504L10.4075 5.07421C10.5597 4.92187 10.6451 4.71536 10.6451 4.50004C10.6451 4.28473 10.5597 4.07822 10.4075 3.92588L6.88669 0.405045C6.73267 0.261525 6.52895 0.18339 6.31846 0.187105C6.10797 0.190818 5.90713 0.27609 5.75827 0.424955C5.6094 0.573819 5.52413 0.774653 5.52042 0.985146C5.5167 1.19564 5.59484 1.39935 5.73836 1.55338L7.87252 3.68754L1.43752 3.68754C1.22204 3.68754 1.01537 3.77315 0.863 3.92552C0.710627 4.07789 0.625024 4.28456 0.625024 4.50004Z"/>
                  </svg>
                </a>
              </div>
            </div>
          </div>

          {/* Sidebar Left */}
          <div className='w-full lg:w-[25%] flex flex-col mb-12 lg:mb-0'>
            {/* Standings */}
            <div className='flex flex-col w-full divide-y-2 divide-gray-700'>
              <h3 className='pb-2 text-lg font-bold text-white'>Standings</h3>
              <div className='w-full'>
                <StandingCard/>
              </div>
            </div>

            {/* Popular Post */}
            <div className='flex-col hidden w-full p-0 divide-y-2 divide-gray-700 lg:flex'>
              <h3 className='pb-2 text-lg font-bold text-white'>Popular Post</h3>
              <div className='w-full'>
              {postStore.listPost.map((item, idx) => {
                if(idx < 4) {
                  return <PopularPost post={item} key={idx}/>
                }
                  return null;
              })}
                <div className='flex flex-row items-center justify-end w-full mt-2 space-x-1'>
                  <a href='/news' className='font-semibold text-[1vw] text-white hover:text-secondary flex flex-row space-x-1 items-center'>
                    <span>More News</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="9" viewBox="0 0 11 9" className='fill-current'>
                    <path d="M0.625024 4.50004C0.625024 4.71553 0.710627 4.92219 0.863 5.07457C1.01537 5.22694 1.22204 5.31254 1.43752 5.31254L7.87252 5.31254L5.73836 7.44671C5.59484 7.60073 5.5167 7.80445 5.52042 8.01494C5.52413 8.22544 5.6094 8.42627 5.75827 8.57513C5.90713 8.724 6.10796 8.80927 6.31846 8.81298C6.52895 8.8167 6.73267 8.73856 6.88669 8.59504L10.4075 5.07421C10.5597 4.92187 10.6451 4.71536 10.6451 4.50004C10.6451 4.28473 10.5597 4.07822 10.4075 3.92588L6.88669 0.405045C6.73267 0.261525 6.52895 0.18339 6.31846 0.187105C6.10797 0.190818 5.90713 0.27609 5.75827 0.424955C5.6094 0.573819 5.52413 0.774653 5.52042 0.985146C5.5167 1.19564 5.59484 1.39935 5.73836 1.55338L7.87252 3.68754L1.43752 3.68754C1.22204 3.68754 1.01537 3.77315 0.863 3.92552C0.710627 4.07789 0.625024 4.28456 0.625024 4.50004Z"/>
                    </svg>
                  </a>
                </div>
              </div>
            </div>

            {/* Social Media Link */}
            <div className='hidden md:flex md:mb-[15vh]'>
              <SocialMediaCard/>
            </div>

          </div>
        </div>
        {/* End Post Grid */}
      </div>

      {/* Start CTA */}
      <div className="relative overflow-hidden bg-cover bg-center h-full bg-transparent lg:-mt-[28vh] -mt-[32vw]">
        <div className="w-full px-0 lg:px-20 xl:px-40 h-[55vw] lg:h-[45vw] bg-bg-section1 bg-no-repeat bg-cover bg-center transform skew-y-2 clip-trapezium mt-24">
          <div className='flex flex-row items-center justify-center w-full h-full transform -skew-y-2'>
            <div className='hidden w-1/2 lg:flex flex-col justify-center items-start lg:pr-[2vw] space-y-5'>
              <h2 className='font-bold text-white lg:text-4xl 2xl:text-5xl' data-aos="fade-right" data-aos-duration="800" data-aos-delay="300" data-aos-easing="ease-in-sine" data-aos-once="true">Watch Live Match on Virtual Stadium</h2>
              <p className='font-normal text-white lg:text-lg 2xl:text-xl' data-aos="fade-right" data-aos-duration="800" data-aos-delay="500" data-aos-easing="ease-in-sine" data-aos-once="true">Feel the experience of watching our live match from Virtual Stadium!</p>
              <a href='https://ifel.id/virtual-stadium-example' className='px-8 text-sm text-white capitalize btn bg-secondary' data-aos="fade-right" data-aos-duration="800" data-aos-delay="700" data-aos-easing="ease-in-sine" data-aos-once="true">Virtual Stadium</a>
            </div>
            <div className='flex flex-col items-center justify-center w-2/3 h-full font-bold text-center text-white lg:hidden'>
              <a href='https://ifel.id/virtual-stadium-example' className='text-[5vw] leading-6'>
                Watch Live Match on Virtual Stadium <span className='text-[2.8vw] h-full'> ▶</span>
              </a>     
              <p className='font-normal text-[2.5vw]'>
                Feel the experience of watching our live match from Virtual Stadium!
              </p>    
            </div>
            <div className='flex-col items-center justify-center hidden w-1/2 lg:flex'>
              <img src='assets/images/dandy.png' alt="cta" className='lg:w-3/4 xl:w-2/3' data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-in-sine" data-aos-once="true"/>
            </div>
          </div>
        </div>
        <div className="w-full h-[55vw] lg:h-[45vw] bg-bg-section2 bg-no-repeat bg-cover bg-center lg:px-20 xl:px-40 transform -skew-y-2 clip-trapezium -mt-10 lg:-mt-32 xl:-mt-28 -z-10">
          <div className='flex flex-row items-center justify-center w-full h-full transform skew-y-2'>
            <div className='hidden w-1/2 lg:flex flex-col justify-center items-start lg:pr-[2vw] space-y-5 -mt-14'>
              <h2 className='font-bold text-white lg:text-4xl 2xl:text-5xl' data-aos="fade-right" data-aos-duration="800" data-aos-delay="300" data-aos-easing="ease-in-sine" data-aos-once="true">Join the tournaments and become champion</h2>
              <p className='font-normal text-white lg:text-lg 2xl:text-xl' data-aos="fade-right" data-aos-duration="800" data-aos-delay="500" data-aos-easing="ease-in-sine" data-aos-once="true">Play different Virtual Football tournaments and compete with other players around the Indonesia.</p>
              <a href='/' className='px-4 py-3 text-xl font-extrabold text-white transition duration-500 rounded-lg hover:bg-white hover:text-secondary' data-aos="fade-right" data-aos-duration="800" data-aos-delay="700" data-aos-easing="ease-in-sine" data-aos-once="true">COMING VERY SOON</a>
            </div>
            <div className='flex flex-col items-center justify-center w-2/3 h-full -mt-6 font-bold text-center text-white lg:hidden'>
              <a href='/' className='text-[5vw] leading-6'>
                Join the tournaments and become champion <span className='text-[2.8vw] h-full'> ▶</span>
              </a>     
              <p className='font-normal text-[2.5vw]'>
                Play different Virtual Football tournaments and compete with other players around the Indonesia.
              </p>    
            </div>
            <div className='flex-col items-end justify-center hidden w-1/2 lg:flex'>
              <img src='assets/images/cewe.png' alt="cta" className='lg:-mb-20 xl:-mb-16' data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-in-sine" data-aos-once="true"/>
            </div>
          </div>
        </div>
        <div className="w-full h-[50vw] lg:h-[38vw] bg-bg-section3 bg-no-repeat bg-cover bg-center lg:px-20 xl:px-40 transform skew-y-2 clip-trapezium -mt-12 lg:-mt-40 xl:-mt-32">
          <div className='flex flex-row items-center justify-center w-full h-full transform -skew-y-2'>
            <div className='hidden w-1/2 lg:flex flex-col justify-center items-start lg:pr-[2vw] space-y-5'>
              <h2 className='font-bold text-white lg:text-4xl 2xl:text-5xl' data-aos="fade-right" data-aos-duration="800" data-aos-delay="300" data-aos-easing="ease-in-sine" data-aos-once="true">Indonesian Football e-League Store</h2>
              <p className='font-normal text-white lg:text-lg 2xl:text-xl' data-aos="fade-right" data-aos-duration="800" data-aos-delay="500" data-aos-easing="ease-in-sine" data-aos-once="true">Explore our collection and find the perfect gear that reflects your unique style and elevates your Virtual Football persona</p>
              <a href='/store' className='px-8 text-sm text-white capitalize btn bg-secondary' data-aos="fade-right" data-aos-duration="800" data-aos-delay="500" data-aos-easing="ease-in-sine" data-aos-once="true">Shop Now</a>
            </div>
            <div className='flex flex-col items-center justify-center w-2/3 h-full font-bold text-center text-white lg:hidden'>
              <a href='/store' className='text-[5vw] leading-6'>
              Indonesian Football e-League Store<span className='text-[2.8vw] h-full'> ▶</span>
              </a>        
            </div>
            <div className='flex-col items-center justify-center hidden w-1/2 lg:flex'>
              <img src='assets/images/jersey.png' alt="cta" className='w-[85%]' data-aos="fade-left" data-aos-duration="800" data-aos-delay="300" data-aos-easing="ease-in-sine" data-aos-once="true"/>
            </div>
          </div>
        </div>
      </div>
      {/* End CTA */}

      {/* Start Youtube Section */}  
      <div className='relative inset-0 z-30 w-full h-full cursor-pointer lg:-mt-16 xl:-mt-6' onClick={ () => window.open('https://www.youtube.com/c/IndonesianFootballeLeague')}>
        <div className='lg:h-[8vh] w-full bg-nav2'>
        </div>
        <div className='flex flex-row items-center justify-between w-full h-full bg-center bg-no-repeat bg-cover bg-nav2 bg-bg-youtube-cta'>
          <div className='w-[30%] lg:w-1/4 xl:w-1/2'>
            <a href='https://www.youtube.com/c/IndonesianFootballeLeague'><img src='assets/images/youtube-cta.png' alt='yt' /></a>
          </div>
          <div className='flex flex-col w-1/2 pl-16 text-center text-white lg:pl-36 lg:space-y-2 xl:space-y-4'>
              <span className='text-[2.8vw] lg:text-3xl xl:text-4xl font-bold'>SUBSCRIBE TO</span>
              <span className='text-[1.8vw] lg:text-lg xl:text-xl font-semibold'>INDONESIAN FOOTBALL e-LEAGUE</span>
          </div>
        </div>  
      </div>  
      {/* End Youtube Section */}

      {/* Start Our Parterns */}
      <div className='flex flex-col items-center justify-center w-full h-full pt-4 space-y-2 lg:pt-8 bg-nav2 lg:space-y-4'>
          <span className='mb-4 text-2xl font-extrabold text-gray-600 lg:text-4xl xl:text-5xl lg:mb-8'>Our Clients</span>
          <div className='w-full h-full'>
              <ClientLogo />
          </div>
      </div>
      {/* End Our Parterns */}
    </div>
  )
}

export default observer(Home)