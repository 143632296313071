import { observer } from 'mobx-react-lite'
import React, { ReactNode, useEffect, useState } from 'react'
import PopularPost from '../../app/component/posts/PopularPost'
import StandingCard from '../../app/component/card/StandingCard'
import AdsBannerInner from '../../app/component/ads/AdsBannerInner'
import CarouselHome from '../../app/component/carousel/CarouselHome'
import './news.css';
import { useLocation } from 'react-router-dom'
import { useStore } from '../../app/stores/store'

type ProfileProps = {
    children: ReactNode;
};

const NewsIndexPage: React.FC<ProfileProps> = ({ children }) => {

    let location = useLocation();

    const { postStore } = useStore();
  
    const pathnames = location.pathname.split("/").filter(x => x);

    const [showPopupInner, setShowPopupInner] = useState(false);
    
    const handleCloseInner: () => void = () => {
        setShowPopupInner(false);
    };

    useEffect(() => {
      postStore.getListPost(1);
    }, [postStore]);

  return (
    <div className='w-full h-full font-sans p-0 m-0 bg-nav1'>
      <div className='w-full h-full bg-bg-home bg-cover bg-no-repeat lg:pb-[20vh]'>
        {!pathnames[1] && <CarouselHome/>}
        <div className='py-5 w-full h-full'>
        {showPopupInner && <AdsBannerInner onClose={handleCloseInner}/>}
        </div>
        {/* Start Post Grid */}
        <div className='w-full h-full lg:px-20 xl:px-40 flex flex-row space-x-10'>

          <div className='w-[100%] lg:w-[70%] flex flex-col divide-y-2 divide-gray-700 mx-6 lg:mx-0'>
            {children}
          </div>

          {/* Sidebar Left */}
          <div className='hidden lg:w-[25%] lg:flex flex-col'>
            {/* Popular Post */}
            <div className='hidden w-full lg:flex flex-col divide-y-2 divide-gray-700 p-0'>
              <h3 className='text-white font-bold text-lg pb-2'>Popular Post</h3>
              <div className='w-full'>
              {postStore.listPost?.map((item, idx) => {
                if(idx < 4) {
                 return <PopularPost post={item} key={idx}/>
                }
                  return null;
                })}
                <div className='w-full flex flex-row justify-end items-center space-x-1 mt-2'>
                  <a href='/news' className='font-semibold text-[1vw] text-white hover:text-secondary flex flex-row space-x-1 items-center'>
                    <span>More News</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="9" viewBox="0 0 11 9" className='fill-current'>
                    <path d="M0.625024 4.50004C0.625024 4.71553 0.710627 4.92219 0.863 5.07457C1.01537 5.22694 1.22204 5.31254 1.43752 5.31254L7.87252 5.31254L5.73836 7.44671C5.59484 7.60073 5.5167 7.80445 5.52042 8.01494C5.52413 8.22544 5.6094 8.42627 5.75827 8.57513C5.90713 8.724 6.10796 8.80927 6.31846 8.81298C6.52895 8.8167 6.73267 8.73856 6.88669 8.59504L10.4075 5.07421C10.5597 4.92187 10.6451 4.71536 10.6451 4.50004C10.6451 4.28473 10.5597 4.07822 10.4075 3.92588L6.88669 0.405045C6.73267 0.261525 6.52895 0.18339 6.31846 0.187105C6.10797 0.190818 5.90713 0.27609 5.75827 0.424955C5.6094 0.573819 5.52413 0.774653 5.52042 0.985146C5.5167 1.19564 5.59484 1.39935 5.73836 1.55338L7.87252 3.68754L1.43752 3.68754C1.22204 3.68754 1.01537 3.77315 0.863 3.92552C0.710627 4.07789 0.625024 4.28456 0.625024 4.50004Z"/>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            {/* Standings */}
            <div className='hidden w-full lg:flex flex-col divide-y-2 divide-gray-700'>
              <h3 className='text-white font-bold text-lg pb-2'>Standings</h3>
              <div className='w-full'>
                <StandingCard/>
              </div>
            </div>
            {/* Social Media Link */}
            <div className='hidden w-full lg:flex flex-col divide-y-2 divide-gray-700 p-0 mt-8'>
              <h3 className='text-white font-bold text-lg pb-2' >Follow Our Social Media</h3>
              <div className='w-full flex flex-row space-x-4 justify-start pt-4'>
                <div className='bg-nav1 w-10 h-10 flex justify-center items-center rounded-sm text-white hover:text-secondary hover:bg-nav2 transition duration-500'>
                  <a href="https://www.instagram.com/ifel.id/">
                    <svg width="24" height="24" viewBox="0 0 24 24" className="fill-current" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.9999 8.09462C9.84951 8.09462 8.09463 9.8495 8.09463 11.9999C8.09463 14.1503 9.84951 15.9052 11.9999 15.9052C14.1503 15.9052 15.9052 14.1503 15.9052 11.9999C15.9052 9.8495 14.1503 8.09462 11.9999 8.09462ZM23.7128 11.9999C23.7128 10.3827 23.7275 8.78016 23.6366 7.16591C23.5458 5.29091 23.1181 3.62685 21.747 2.25575C20.373 0.88173 18.7118 0.456926 16.8368 0.366106C15.2196 0.275285 13.6171 0.289934 12.0028 0.289934C10.3856 0.289934 8.7831 0.275285 7.16885 0.366106C5.29384 0.456926 3.62978 0.88466 2.25869 2.25575C0.884661 3.62978 0.459856 5.29091 0.369035 7.16591C0.278215 8.78309 0.292864 10.3856 0.292864 11.9999C0.292864 13.6141 0.278215 15.2196 0.369035 16.8339C0.459856 18.7089 0.887591 20.3729 2.25869 21.744C3.63271 23.118 5.29384 23.5429 7.16885 23.6337C8.78603 23.7245 10.3886 23.7098 12.0028 23.7098C13.62 23.7098 15.2226 23.7245 16.8368 23.6337C18.7118 23.5429 20.3759 23.1151 21.747 21.744C23.121 20.37 23.5458 18.7089 23.6366 16.8339C23.7304 15.2196 23.7128 13.6171 23.7128 11.9999ZM11.9999 18.0087C8.6747 18.0087 5.99111 15.3251 5.99111 11.9999C5.99111 8.6747 8.6747 5.9911 11.9999 5.9911C15.3251 5.9911 18.0087 8.6747 18.0087 11.9999C18.0087 15.3251 15.3251 18.0087 11.9999 18.0087ZM18.2548 7.14833C17.4784 7.14833 16.8515 6.52138 16.8515 5.74501C16.8515 4.96864 17.4784 4.34169 18.2548 4.34169C19.0312 4.34169 19.6581 4.96864 19.6581 5.74501C19.6583 5.92936 19.6222 6.11195 19.5518 6.28231C19.4813 6.45267 19.378 6.60747 19.2476 6.73782C19.1172 6.86818 18.9625 6.97154 18.7921 7.04198C18.6217 7.11242 18.4391 7.14856 18.2548 7.14833Z"/>
                    </svg>
                  </a> 
                </div>
                <div className='bg-nav1 w-10 h-10 flex justify-center items-center rounded-sm text-white hover:text-secondary hover:bg-nav2 transition duration-500'>
                  <a href="https://www.facebook.com/ifel.id/">
                    <svg width="14" height="24" viewBox="0 0 14 24" className="fill-current" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5845 13.5002L13.2512 9.15698H9.08349V6.33848C9.08349 5.15048 9.66549 3.99173 11.5322 3.99173H13.4267V0.294227C13.4267 0.294227 11.7077 0.000976562 10.0637 0.000976562C6.63174 0.000976562 4.38849 2.08148 4.38849 5.84723V9.15773H0.573242V13.501H4.38849V24.001H9.08349V13.501L12.5845 13.5002Z"/>
                    </svg>
                  </a>
                </div>
                <div className='bg-nav1 w-10 h-10 flex justify-center items-center rounded-sm text-white hover:text-secondary hover:bg-nav2 transition duration-500'>
                  <a href="https://www.youtube.com/c/IndonesianFootballeLeague">
                    <svg width="24" height="18" viewBox="0 0 24 18" className='fill-current' xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.5001 3.50708C23.3642 3.02231 23.0995 2.58342 22.7341 2.23708C22.3584 1.88008 21.8979 1.62471 21.3961 1.49508C19.5181 1.00008 11.9941 1.00008 11.9941 1.00008C8.85746 0.964389 5.72156 1.12135 2.60413 1.47008C2.10232 1.60929 1.64269 1.87036 1.26613 2.23008C0.896129 2.58608 0.628129 3.02508 0.488129 3.50608C0.151822 5.31782 -0.0115791 7.15742 0.000128902 9.00008C-0.0118711 10.8411 0.151129 12.6801 0.488129 14.4941C0.625129 14.9731 0.892129 15.4101 1.26313 15.7631C1.63413 16.1161 2.09613 16.3711 2.60413 16.5061C4.50713 17.0001 11.9941 17.0001 11.9941 17.0001C15.1348 17.0358 18.2747 16.8789 21.3961 16.5301C21.8979 16.4004 22.3584 16.1451 22.7341 15.7881C23.0994 15.4418 23.3638 15.0029 23.4991 14.5181C23.8442 12.707 24.012 10.8667 24.0001 9.02308C24.0261 7.17168 23.8585 5.32264 23.5001 3.50608V3.50708ZM9.60213 12.4241V5.57708L15.8621 9.00108L9.60213 12.4241Z"/>
                    </svg>
                  </a>
                </div>
                <div className='bg-nav1 w-10 h-10 flex justify-center items-center rounded-sm text-white hover:text-secondary hover:bg-nav2 transition duration-500'>
                  <a href="https://www.tiktok.com/@ifel_id">
                    <svg width="18" height="20" viewBox="0 0 18 20" className='fill-current' xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.5901 4.69C15.5933 4.47079 14.6915 3.94144 14.0142 3.17789C13.3369 2.41435 12.9189 1.45587 12.8201 0.44V0H9.37014V13.67C9.37085 14.2766 9.18068 14.868 8.82659 15.3604C8.47249 15.8529 7.97242 16.2215 7.39721 16.414C6.82199 16.6065 6.2008 16.6131 5.62162 16.4329C5.04244 16.2527 4.53464 15.8948 4.17015 15.41C3.84359 14.9808 3.64363 14.4689 3.59287 13.932C3.54212 13.3951 3.64259 12.8548 3.88292 12.372C4.12326 11.8892 4.49388 11.4834 4.95286 11.2003C5.41184 10.9172 5.94088 10.7681 6.48014 10.77C6.77839 10.7683 7.07514 10.8121 7.36015 10.9V7.4C7.02848 7.35893 6.69425 7.34222 6.36015 7.35C5.12946 7.38288 3.93506 7.77393 2.92323 8.47527C1.91139 9.1766 1.12604 10.1578 0.663321 11.2986C0.200607 12.4395 0.0806234 13.6905 0.318062 14.8986C0.5555 16.1066 1.14006 17.2191 2.00015 18.1C2.88163 18.9962 4.00964 19.6101 5.24084 19.8638C6.47204 20.1175 7.75086 19.9994 8.9148 19.5246C10.0787 19.0498 11.0753 18.2397 11.7778 17.1973C12.4803 16.1548 12.857 14.9271 12.8601 13.67V6.67C14.2516 7.66395 15.9202 8.19565 17.6301 8.19V4.79C17.2942 4.79139 16.9591 4.75787 16.6301 4.69H16.5901Z"/>
                    </svg>
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>
        {/* End Post Grid */}
      </div>
    </div>
  )
}

export default observer(NewsIndexPage)