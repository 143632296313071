import { Typography } from 'antd';
import React, { useState } from 'react'
import { IContact } from '../../app/models/contacts';
import { useStore } from '../../app/stores/store';

const initialValuesContact:IContact = {
    type: "default",
    name: '-',
    email: '-',
    messages: '-'
}

function ContactPage() {

    const { Title } = Typography;

    const { accountStore } = useStore();

    const [valueContact, setValueContact] = useState<IContact>(initialValuesContact);

    const handleInputEmail = (value: string) => {
        setValueContact(prevState => ({...prevState, email: value}));
    }

    const handleInputName = (value: string) => {
        setValueContact(prevState => ({...prevState, name: value}));
    }

    const handleInputMessages = (value: string) => {
        setValueContact(prevState => ({...prevState, messages: value}));
    }

    const handleInputType = (value: string) => {
        setValueContact(prevState => ({...prevState, type: value}));
    }

    const handleSubmit = () => {
        //console.log(valueContact);
    };

  return (
    <div className='w-full h-[100wh] lg:px-40 lg:py-12 bg-bg-home bg-primary bg-no-repeat bg-cover font-sans'>
      <style>{`
        .bg-pass input {
          background: #1C1F2A;
          color: white;
        }
        `}
      </style>
      <div className='w-full h-[620px] flex flex-row bg-primary lg:rounded-xl'>
        <div className='relative flex-col hidden w-6/12 px-12 pt-16 bg-no-repeat bg-cover rounded-l-lg bg-bg-contact bg-secondary lg:flex'>
          <div className='text-white'>
            <h1 className='font-extrabold text-[58px]'>CONTACT US</h1>
            <p className='font-bold text-[1.8vw]'>
            We are thrilled to collaborate with businesses and organizations that share our passion for e-sports and Virtual Football.
            </p>
          </div>
        </div>
        <div className='flex flex-col w-full p-4 lg:w-6/12 lg:pt-20 lg:px-28'>
          <div className='w-full'>
            <Title level={3} style={{ color: '#ffffff', fontSize: '28px', fontWeight: 'bold'}}>Contact Us</Title>
            <p style={{ color: 'white', marginBottom: 25}}>If you are interested in sponsoring or partnering opportunities with us, please contact <b>business@ifel.id</b> or fill up the form below.</p>
          </div>
          <div className='flex flex-col w-full h-full space-y-2'>   
            <div>
                <div className="relative w-full">
                    <select className="select w-full bg-nav1 text-gray-600 text-[2.4vw] lg:text-[0.8vw]" value={valueContact.type} onChange={(e) => handleInputType(e.target.value)}>
                        {/* <option disabled value="default">Select Contact Type</option> */}
                        <option value="partnership">Partnership</option>
                        <option value="sponsorship">Sponsorship</option>
                    </select>
                </div>    
                {(valueContact.type === null || valueContact.type === '') && <p id="outlined_error_help" className="ml-2 mt-2 text-[2.2vw] lg:text-[0.6vw] text-right text-red-600 dark:text-red-400">Pilih contact type!</p> }
            </div>
            <div>
                <div className="relative w-full">
                    <input type="text" id="small_outlined2" className="block px-2.5 pb-1.5 pt-6 w-full text-xs border-nav2 text-white bg-nav1 rounded-lg appearance-none dark:text-white peer" placeholder=" " onChange={(e) => handleInputName(e.target.value)} />
                    <label htmlFor="small_outlined2" className="absolute text-[2.2vw] lg:text-[0.8vw] text-gray-600 font-semibold dark:text-gray-400 duration-300 transform -translate-y-1 scale-75 top-2 z-10 origin-[0] bg-nav1 dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-1 left-1">Name</label>
                </div>    
                {(valueContact.name === null || valueContact.name === '') && <p id="outlined_error_help" className="ml-2 mt-2 text-[2.2vw] lg:text-[0.6vw] text-right text-red-600 dark:text-red-400"><span className="font-medium">Name</span> tidak boleh kosong!</p> }
            </div>
            <div>
                <div className="relative w-full">
                    <input type="text" id="small_outlined3" className="block px-2.5 pb-1.5 pt-6 w-full text-xs border-nav2 text-white bg-nav1 rounded-lg appearance-none dark:text-white peer" placeholder=" " onChange={(e) => handleInputEmail(e.target.value)} />
                    <label htmlFor="small_outlined3" className="absolute text-[2.2vw] lg:text-[0.8vw] text-gray-600 font-semibold dark:text-gray-400 duration-300 transform -translate-y-1 scale-75 top-2 z-10 origin-[0] bg-nav1 dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-1 left-1">Email Address</label>
                </div>    
                {(valueContact.email === null || valueContact.email === '') && <p id="outlined_error_help" className="ml-2 mt-2 text-[2.2vw] lg:text-[0.6vw] text-right text-red-600 dark:text-red-400"><span className="font-medium">Email</span> tidak boleh kosong!</p> }
            </div>
            <div>
                <div className="relative w-full">
                    <input type="text" id="small_outlined4" className="block px-2.5 pb-1.5 pt-6 w-full text-xs border-nav2 text-white bg-nav1 rounded-lg appearance-none dark:text-white peer" placeholder=" " onChange={(e) => handleInputMessages(e.target.value)} />
                    <label htmlFor="small_outlined4" className="absolute text-[2.2vw] lg:text-[0.8vw] text-gray-600 font-semibold dark:text-gray-400 duration-300 transform -translate-y-1 scale-75 top-2 z-10 origin-[0] bg-nav1 dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-1 left-1">Messages</label>
                </div>    
                {(valueContact.messages === null || valueContact.messages === '') && <p id="outlined_error_help" className="ml-2 mt-2 text-[2.2vw] lg:text-[0.6vw] text-right text-red-600 dark:text-red-400"><span className="font-medium">Messages</span> tidak boleh kosong!</p> }
            </div>
            <div className='w-full h-full pt-2 text-left'>
                {!accountStore.loadingLogin ? 
                    <button onClick={() => handleSubmit()} disabled={!valueContact.email || valueContact.name === ''} className={`uppercase px-10 lg:px-20 py-3 disabled:bg-gray-600 disabled:cursor-not-allowed disabled:text-gray-700 text-white font-semibold bg-secondary border-none rounded-md text-[2vw] lg:text-[0.8vw] hover:bg-nav2 hover:text-secondary transition duration-300`}>Submit</button> : 
                    <button onClick={() => handleSubmit()} disabled={true} className={`loading uppercase px-10 lg:px-20 py-3 disabled:bg-secondary disabled:cursor-not-allowed disabled:text-white text-white font-semibold bg-secondary border-none rounded-md text-[2vw] lg:text-[0.8vw] hover:bg-nav2 hover:text-secondary transition duration-300`}>Please Wait</button>
                }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactPage