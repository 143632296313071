import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStore } from '../../stores/store';
import './topNavbar.css';

function TopNavbar() {

    const { accountStore } = useStore();

    useEffect(() => {
        accountStore.getNotifications();
    }, [accountStore]);

    const handleClickNotification = () => {
        if(accountStore.notification.filter((item) => item.status === 1).length > 0) {
            accountStore.readNotifications();
        };
    };

if(accountStore.isLoggedIn) {
    return (
        <div className='font-sans w-full h-full px-6 py-2 lg:px-20 xl:px-40 bg-nav2 lg:bg-nav1 flex flex-row justify-end items-center space-x-8'>
            <details className={`dropdown dropdown-end`} onClick={handleClickNotification}>
            {accountStore.notification.filter((item) => item.status === 1).length > 0 ? (
                <summary tabIndex={0} className="static avatar cursor-pointer flex flex-row items-center justify-center">
                    <div className='w-full h-full flex flex-row items-center justify-center'>
                        <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='object-contain'>
                            <g id="SVGRepo_bgCarrier"></g>
                            <g id="SVGRepo_tracerCarrier"></g><g id="SVGRepo_iconCarrier">
                            <path d="M19.3399 14.49L18.3399 12.83C18.1299 12.46 17.9399 11.76 17.9399 11.35V8.82C17.9399 6.47 16.5599 4.44 14.5699 3.49C14.0499 2.57 13.0899 2 11.9899 2C10.8999 2 9.91994 2.59 9.39994 3.52C7.44994 4.49 6.09994 6.5 6.09994 8.82V11.35C6.09994 11.76 5.90994 12.46 5.69994 12.82L4.68994 14.49C4.28994 15.16 4.19994 15.9 4.44994 16.58C4.68994 17.25 5.25994 17.77 5.99994 18.02C7.93994 18.68 9.97994 19 12.0199 19C14.0599 19 16.0999 18.68 18.0399 18.03C18.7399 17.8 19.2799 17.27 19.5399 16.58C19.7999 15.89 19.7299 15.13 19.3399 14.49Z" fill="#ffffff"></path>
                            <path d="M14.8297 20.01C14.4097 21.17 13.2997 22 11.9997 22C11.2097 22 10.4297 21.68 9.87969 21.11C9.55969 20.81 9.31969 20.41 9.17969 20C9.30969 20.02 9.43969 20.03 9.57969 20.05C9.80969 20.08 10.0497 20.11 10.2897 20.13C10.8597 20.18 11.4397 20.21 12.0197 20.21C12.5897 20.21 13.1597 20.18 13.7197 20.13C13.9297 20.11 14.1397 20.1 14.3397 20.07C14.4997 20.05 14.6597 20.03 14.8297 20.01Z" fill="#ffffff"></path>
                            </g>
                        </svg>
                    </div>
                    <div className="absolute top-0 right-0 badge badge-info badge-xs w-2"></div>
                </summary>
                ) : (
                    <summary tabIndex={0} className="avatar cursor-pointer flex flex-row items-center justify-center">
                    <div className='w-full h-full flex flex-row items-center justify-center'>
                        <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='object-contain'>
                            <g id="SVGRepo_bgCarrier"></g>
                            <g id="SVGRepo_tracerCarrier"></g><g id="SVGRepo_iconCarrier">
                            <path d="M19.3399 14.49L18.3399 12.83C18.1299 12.46 17.9399 11.76 17.9399 11.35V8.82C17.9399 6.47 16.5599 4.44 14.5699 3.49C14.0499 2.57 13.0899 2 11.9899 2C10.8999 2 9.91994 2.59 9.39994 3.52C7.44994 4.49 6.09994 6.5 6.09994 8.82V11.35C6.09994 11.76 5.90994 12.46 5.69994 12.82L4.68994 14.49C4.28994 15.16 4.19994 15.9 4.44994 16.58C4.68994 17.25 5.25994 17.77 5.99994 18.02C7.93994 18.68 9.97994 19 12.0199 19C14.0599 19 16.0999 18.68 18.0399 18.03C18.7399 17.8 19.2799 17.27 19.5399 16.58C19.7999 15.89 19.7299 15.13 19.3399 14.49Z" fill="#ffffff"></path>
                            <path d="M14.8297 20.01C14.4097 21.17 13.2997 22 11.9997 22C11.2097 22 10.4297 21.68 9.87969 21.11C9.55969 20.81 9.31969 20.41 9.17969 20C9.30969 20.02 9.43969 20.03 9.57969 20.05C9.80969 20.08 10.0497 20.11 10.2897 20.13C10.8597 20.18 11.4397 20.21 12.0197 20.21C12.5897 20.21 13.1597 20.18 13.7197 20.13C13.9297 20.11 14.1397 20.1 14.3397 20.07C14.4997 20.05 14.6597 20.03 14.8297 20.01Z" fill="#ffffff"></path>
                            </g>
                        </svg>
                    </div>
                </summary>
                )
            }
                <ul tabIndex={0} className="dropdown-content menu p-1 shadow bg-nav2 w-60 lg:w-80 text-white text-xs font-bold divide-y divide-solid divide-gray-600 rounded-md">
                    { accountStore.notification.length > 0 ? 
                        accountStore.notification.map((notification, index) => (
                            <div className='w-full h-full' key={index}>
                                <div className='w-full h-full flex flex-row justify-center py-3 px-2'>
                                    <div className='w-1/12 hover:none'>
                                        <img src='assets/images/icon-notification.png' className='w-3 lg:w-4 lg:h-4' alt='icon'></img>
                                    </div>
                                    <div className='w-11/12'>
                                        <span className='text-[2.2vw] lg:text-xs'>{notification.description} <span className='text-secondary'>#{notification.transaction.order_id}</span></span>
                                    </div>
                                </div>
                                <hr className='border-1 border-gray-500'/>
                            </div>
                        )) : (
                            <div className='w-full h-12 flex flex-row justify-center items-center'>
                                <span className='text-[2.2vw] lg:text-xs'>No Notification</span>
                            </div>
                        )
                    }
                    
                </ul>
            </details>
            <details className="dropdown dropdown-end">
                <summary tabIndex={0} className="avatar cursor-pointer flex flex-row items-center justify-center">
                    <div className="w-8 rounded-full flex flex-row items-center justify-center">
                        <img src={accountStore.user?.avatar} alt='avatar'/>
                    </div>
                </summary>
                <ul tabIndex={0} className="dropdown-content menu p-1 shadow bg-nav2 w-40 text-white text-xs font-bold divide-y divide-solid divide-gray-600 rounded-lg">
                    <li><a href='/profile' className='hover:text-secondary'>My Profile</a></li>
                    <div>
                        <li><a href='/setting-password' className='hover:text-secondary'>Setting Password</a></li>
                        <li><a href='/profile-tournament' className='hover:text-secondary'>Tournament</a></li>
                        <li><a href='/transaction-history' className='hover:text-secondary'>Transaction History</a></li>
                    </div>
                    <li className='flex flex-row'>
                        <a href='/' onClick={() => accountStore.logout() } className='hover:text-secondary w-full'>
                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor"><g id="SVGRepo_bgCarrier"></g><g id="SVGRepo_tracerCarrier"></g><g id="SVGRepo_iconCarrier"> <path d="M15 16.5V19C15 20.1046 14.1046 21 13 21H6C4.89543 21 4 20.1046 4 19V5C4 3.89543 4.89543 3 6 3H13C14.1046 3 15 3.89543 15 5V8.0625M11 12H21M21 12L18.5 9.5M21 12L18.5 14.5"></path> </g></svg>
                            Logout
                        </a>
                    </li>
                </ul>
            </details>
        </div>
     )
} else {
    return (
        <div className='font-sans w-full h-full px-6 lg:px-20 xl:px-40 py-2 bg-nav1 flex flex-row justify-end items-center space-x-4'>
            <a href='/login' className='text-white text-xs font-semibold'>Login</a>
            <a href='/register' className='text-white text-xs bg-secondary font-semibold hover:bg-nav2 hover:text-secondary px-4 py-[6px] rounded-md'>Register</a>
        </div>
     )
}
  
}

export default observer(TopNavbar)