export function toPercentByCategory(nominal_jenis: number | undefined, total: number | undefined) {
    const res = Math.round((nominal_jenis || 0) / (total || 0) * 100);
    return res;
}

export const formatRupiah = (amount: number): string => {
    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    });
  
    return formatter.format(amount);
  };

export const shortenText = (maxLength: number, originalText: string): string => {
    if (originalText.length > maxLength) {
      return `${originalText.slice(0, maxLength)}...`;
    }
    return originalText;
};

export const formatDate = (dateString: string): string => {
  const dateObject = new Date(dateString);
  const formattedDate = dateObject.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
    timeZone: 'UTC',
  });
  return formattedDate;
}

export function formatDateSchedule(dateString: string) {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' });
}

export function qualification_stage(data: string | undefined) {
  switch (data) {
    case "0": return 'Group Stage';
    case "1": return 'Grand Final';
    case "2": return 'Semi Final';
    case "3": return 'Quarter Final';
    case "4": return 'Round Of Sixteen';
    case "5": return 'Group Stage'; 
    case "6": return 'Knockout Stage'; 
    case "7": return 'Final Upper Bracket'; 
    case "8": return 'Final Lower Bracket'; 
  }
}

export function calculatePercentage(num1 : number, num2: number) {

  if(num1 === 0 && num2 === 0) {
    return 0;
  }
  const total = num1 + num2;
  const numPercentage = (num1 / total) * 100;

  return numPercentage.toFixed(2);
}

export enum sorting {
  LOWEST_PRICE = 'lowest',
  HIGHEST_PRICE = 'highest',
  NEWEST = 'newest',
  OLDEST = 'oldest',
}