import { makeAutoObservable, runInAction } from "mobx"
import agent from "../api/agent";
import { IPayment, ITransaction, ITransactionList } from "../models/transactions";

export default class TransactionStore {

    loadingForm = false;
    loading = false;
    transactionList: ITransactionList[] | null = null;
    totalTransaction = 0;
    transactionPayment: ITransactionList | null = null;
    snapToken = "";
    
    constructor() {
        makeAutoObservable(this);
    }

    create = async (data: ITransaction) => {
        try {
            this.loadingForm = true;
           return await agent.Transaction.addTransaction(data);
        } catch (error) {
            throw(error);
        } finally {
            runInAction(() => {
                this.loadingForm = false
              })
        }
    }

    payment = async (data: IPayment) => {
        try {
            this.loading = true;
            return await agent.Transaction.payment(data);
        } catch (error) {
            throw(error);
        } finally {
            runInAction(() => {
                this.loading = false
              })
        }
    }

    setSnapToken = (snapToken: string) => {
        this.snapToken = snapToken;
    }

    listTransactionsByUser = async (page: number) => {
        try {
            this.loading = true;
            const result = await agent.Transaction.listTransactionsByUser(page);
            this.setTransactionList(result.data, result.total);
            result.data.forEach(data => {
                if(data.status === 1) {
                    data.status_desc = "unpaid";
                } else if(data.status === 4) {
                    data.status_desc = "paid";
                }
            });
        } catch (error) {
            throw(error);
        } finally {
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    transactionsById= async (id: string | undefined) => {
        try {
            this.loading = true;
            const result = await agent.Transaction.transactionById(id);
            this.setTransactionById(result.data);
        } catch (error) {
            throw(error);
        } finally {
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    setTransactionById = (data: ITransactionList) => {
        this.transactionPayment = data;
    }

    setTransactionList = (data: ITransactionList[], total: number) => {
        this.transactionList = data;
        this.totalTransaction = total;
    }

}