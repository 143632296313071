import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import StandingCard from '../../app/component/card/StandingCard';
import { calculatePercentage, formatDate, qualification_stage } from '../../app/config/enum';
import { IMatchStatistics } from '../../app/models/matches';
import { useStore } from '../../app/stores/store'

// function removeDuplicateHost(url: string | undefined): string {

//     if(url){
//         var urlObj = new URL(url);
//         var { host, pathname, search, hash } = urlObj;
//         var protocol = urlObj.protocol;
//         var normalizedHost = host.replace(/^https?:\/\//, '');
//         return `${protocol}//${normalizedHost}${pathname}${search}${hash}`;
//     }

//     return '';  
    
//   }

function MatchStatistics() {

    const { leagueStore } = useStore();

    const { matchId } = useParams();

    const { statistics } = leagueStore;

    const [show, setShow] = useState<IMatchStatistics>();

    const handleSelectedMatch = (id: number) => {
        if(id ===  statistics?.id) {
            statistics.qualification = qualification_stage(statistics?.qualification_stage?.toString());    
            setShow(statistics);
        } else {
            statistics?.anotherMatch?.map(item => {
                if(item.id === id) {
                        item.qualification = qualification_stage(item.qualification_stage?.toString());
                    return setShow(item);
                } else {
                    return null;
                }
            })
        }
    }

    useEffect(() => {
        leagueStore.getMatchStats(Number(matchId)).then((data) =>  setShow(data));
    }, [matchId, leagueStore]);

  return (
    <div className='w-full h-full pb-12 font-sans bg-no-repeat bg-cover bg-bg-home'>
        <div className='flex-col items-center justify-center hidden w-full h-full pt-4 pb-8 lg:flex bg-nav1'>
            <div className='flex flex-col items-center justify-center w-full h-full'>
                <span className='text-white font-bold mb-2 text-[1.2vw]'>{show?.competition.name}</span>
                <select className="py-0 lg:pl-4 lg:py-1 w-[20%] lg:w-[7%] lg:max-w-xs lg:h-[50%] text-gray-500 text-[1.8vw] lg:text-[0.8vw] bg-nav1 border-gray-700 border-1 active:border-none rounded-sm"
                    onChange={(e) => handleSelectedMatch(Number(e.target.value))}
                >
                <option className='border-none' value={statistics?.id}>Match {statistics?.match_day}</option>
                    {statistics?.anotherMatch?.map((item, idx) => (
                        <option className='border-none' value={item.id} key={idx}>Match {item.match_day}</option>
                    ))}
                </select>
            </div>
            <div className='flex flex-row items-center justify-center w-full h-full'>
                <div className='w-[49%] flex flex-row justify-center items-center py-2'> 
                    <div id='home' className='flex items-center justify-center w-full h-full lg:flex-row'>
                        <div className='w-[75%] flex flex-col text-end font-semibold justify-end mr-6'>
                            <span className='text-white text-[2.8vw] lg:text-[1.3vw]'>{show?.home.name}</span>
                            <span className='text-white text-[2.5vw] lg:text-[0.8vw] font-normal'>{show?.home_player ? show?.home_player.name : ''}</span>
                        </div>
                        <div className='text-end w-[15%] h-[5vw] flex flex-row justify-end items-center'>
                            <div className='lg:px-1 w-[12vw] lg:w-[10vw] lg:h-[8vw]'>
                                <img src={show?.home.flag} alt='logo' className='object-contain w-full h-full' />
                            </div>
                        </div>
                        <div className='w-[6%] text-end items-center flex flex-row justify-end'>
                            <span className='text-white font-semibold text-[2.8vw] lg:text-[1.3vw] flex flex-row items-center'>{show?.result? show?.result.home_score : ''} <span className='text-[0.8vw] font-normal text-gray-600 ml-1'> {(show?.result?.penalty_home !== 0 && show?.result?.penalty_home) && '(' + show?.result?.penalty_home + ')'}</span></span>
                        </div>
                    </div>
                </div>
                <div className='w-[2%] flex flex-row justify-center items-center text-gray-600 font-bold text-[1.2vw]'>
                    {show?.result === null ? (<span>VS</span>) : (<span>-</span>)}
                </div>
                <div className='w-[49%] flex flex-row justify-center items-center py-2'>
                    <div id='home' className='flex flex-row items-center justify-center w-full h-full'>
                        <div className='w-[6%] text-end items-center flex flex-row justify-start'>
                            <span className='text-white font-semibold text-[2.8vw] lg:text-[1.3vw] flex flex-row items-center'><span className='text-[0.8vw] font-normal text-gray-600 mr-1'> {(show?.result?.penalty_away !== 0 && show?.result?.penalty_away) && '(' + show?.result?.penalty_away + ')'}</span> {show?.result ? show?.result.away_score : ''}</span>
                        </div>
                        <div className='text-end w-[15%] h-full flex flex-row justify-start items-center'>
                        <div className='lg:px-1 w-[12vw] lg:w-[10vw] lg:h-[8vw]'>
                                <img src={show?.away.flag} alt='logo' className='object-contain w-full h-full' />
                            </div>
                        </div>
                        <div className='w-[79%] flex flex-col text-start font-semibold justify-start ml-4'>
                            <span className='text-white text-[2.8vw] lg:text-[1.3vw]'>{show?.away.name}</span>
                            <span className='text-white text-[2.5vw] lg:text-[0.8vw] font-normal'>{show?.away_player ? show?.away_player.name : ''}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full h-full text-gray-600 text-[0.6vw] text-center pb-2'> 
                <span>{show?.qualification} / {formatDate(show?.match_date.toString()!)} / {show?.description}</span>
            </div>
        </div>
        <div className='flex flex-col items-center justify-center w-full h-full pt-4 pb-8 lg:hidden bg-nav1'>
            <div className='flex flex-col items-center justify-center w-full h-full lg:hidden'>
                <span className='text-white font-bold text-[4vw] lg:text-[1.2vw]'>{show?.competition.name}</span>
                <span className='text-white font-bold mb-2 text-[4vw] lg:text-[1.2vw]'>{show?.competition.year}</span>
            </div>
            <div className='flex flex-row items-center justify-center w-full h-full'>
                <div className='w-[49%] flex flex-row justify-center items-center py-2 mt-6'> 
                    <div id='home' className='flex items-center justify-center w-full h-full lg:flex-row'>
                        <div className='w-[75%] pl-10 h-full flex flex-col text-center font-semibold justify-center space-y-10 items-center'>
                            <div className='text-center w-full h-[5vw] flex flex-row justify-center items-center'>
                                <div className='lg:px-1 w-[18vw] h-[18vw] lg:w-[10vw] lg:h-[8vw]'>
                                    <img src={show?.home.flag} alt='logo' className='object-contain w-full h-full' />
                                </div>
                            </div>
                            <div className='flex flex-col justify-center w-full font-semibold text-center'>
                                <span className='text-white text-[2.8vw] lg:text-[1.3vw]'>{show?.home.name}</span>
                                <span className='text-white text-[2.5vw] lg:text-[0.8vw] font-normal'>{show?.home_player ? show?.home_player.name : ''}</span>
                            </div>
                        </div>
                        <div className='w-[6%] text-end items-center flex flex-row justify-end -mt-16'>
                            <span className='text-white font-semibold text-[4vw] lg:text-[1.3vw] flex flex-row items-center'>{show?.result? show?.result.home_score : ''} <span className='text-[2.5vw] font-normal text-gray-600 ml-1'> {(show?.result?.penalty_home !== 0 && show?.result?.penalty_home) && '(' + show?.result?.penalty_home + ')'}</span></span>
                        </div>
                    </div>
                </div>
                <div className='w-[1%] -mt-10 flex flex-row justify-center items-center text-gray-600 font-bold text-[5vw] lg:text-[1.2vw]'>
                    {show?.result === null ? (<span>VS</span>) : (<span>-</span>)}
                </div>
                <div className='w-[49%] flex flex-row justify-center items-center py-2 mt-6'> 
                    <div id='home' className='flex items-center justify-center w-full h-full lg:flex-row'>
                        <div className='w-[6%] text-start items-center flex flex-row justify-start -mt-16'>
                            <span className='text-white font-semibold text-[4vw] lg:text-[1.3vw] flex flex-row items-center'><span className='text-[2.5vw] font-normal text-gray-600 mr-1'> {(show?.result?.penalty_away !== 0 && show?.result?.penalty_away) && '(' + show?.result?.penalty_away + ')'}</span> {show?.result? show?.result.away_score : ''}</span>
                        </div>
                        <div className='w-[75%] pr-10 h-full flex flex-col text-center font-semibold justify-center space-y-10 items-center'>
                            <div className='text-center w-full h-[5vw] flex flex-row justify-center items-center'>
                                <div className='lg:px-1 w-[18vw] h-[18vw] lg:w-[10vw] lg:h-[8vw]'>
                                    <img src={show?.away.flag} alt='logo' className='object-contain w-full h-full' />
                                </div>
                            </div>
                            <div className='flex flex-col justify-center w-full font-semibold text-center'>
                                <span className='text-white text-[2.8vw] lg:text-[1.3vw]'>{show?.away.name}</span>
                                <span className='text-white text-[2.5vw] lg:text-[0.8vw] font-normal'>{show?.away_player ? show?.away_player.name : ''}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full h-full text-gray-600 text-[2.5vw] text-center pb-2'> 
                <span>{show?.qualification} / {formatDate(show?.match_date.toString()!)} / {show?.description}</span>
            </div>
            <div className='flex flex-col items-center justify-center w-full h-full lg:hidden'>
                <select className="py-0 lg:pl-4 lg:py-1 w-[20%] lg:w-[7%] lg:max-w-xs lg:h-[50%] text-gray-500 text-[1.8vw] lg:text-[0.8vw] bg-nav1 border-gray-700 border-1 active:border-none rounded-sm"
                    onChange={(e) => handleSelectedMatch(Number(e.target.value))}
                >
                <option className='border-none' value={statistics?.id}>Match {statistics?.match_day}</option>
                    {statistics?.anotherMatch?.map((item, idx) => (
                        <option className='border-none' value={item.id} key={idx}>Match {item.match_day}</option>
                    ))}
                </select>
            </div>
        </div>
        <div className='flex flex-row w-full h-full mt-8 space-x-10 lg:px-20 xl:px-40'>
          <div className='w-[100%] lg:w-[70%] flex flex-col divide-y-2 divide-gray-700 mx-6 lg:mx-0'>
            <h3 className='pb-2 font-bold text-white text-md'>Match Statistics</h3>
            
            {(show?.comp_home && show?.comp_away) ? (
                <div className='w-full h-full flex flex-col space-y-6 justify-start items-center pt-4 text-[2.4vw] lg:text-[1vw]'>
                    <div className='w-full h-[4vw] flex flex-col space-y-2 justify-start items-center text-white font-semibold'>
                        <div className='flex flex-row items-center justify-between w-full h-full'>
                            <span>{show?.comp_home ? show?.comp_home.possession : 0}</span>
                            <span>Possession (%)</span>
                            <span>{show?.comp_away ? show?.comp_away.possession : 0}</span>
                        </div>
                        <div className='flex flex-row items-center justify-between w-full h-full space-x-3'>
                            <div className="relative w-1/2 bg-nav2 rounded-full h-2.5 dark:bg-gray-700">
                                <div className={`absolute ${show?.comp_home.possession > show?.comp_away.possession ? 'bg-blue-600' : 'bg-gray-600'} h-2.5 rounded-full right-0`} style={{ width: `${show?.comp_home.possession.toString()}%`}}></div>
                            </div>
                            <div className="w-1/2 bg-nav1 rounded-full h-2.5 dark:bg-gray-700">
                                <div className={` ${show?.comp_away.possession > show?.comp_home.possession ? 'bg-blue-600' : 'bg-gray-600'} h-2.5 rounded-full`} style={{ width: `${show?.comp_away.possession.toString()}%`}}></div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full h-[4vw] flex flex-col space-y-2 justify-start items-center text-white font-semibold'>
                        <div className='flex flex-row items-center justify-between w-full h-full'>
                            <span>{show?.comp_home ? show?.comp_home.shoots : 0} ({show?.comp_home.on_target})</span>
                            <span>Shots (On Target)</span>
                            <span>{show?.comp_away ? show?.comp_away.shoots : 0} ({show?.comp_away.on_target})</span>
                        </div>
                        <div className='flex flex-row items-center justify-between w-full h-full space-x-3'>
                            <div className="relative w-1/2 bg-nav2 rounded-full h-2.5 dark:bg-gray-700">
                                <div className={`absolute ${show?.comp_home.shoots > show?.comp_away.shoots ? 'bg-blue-600' : 'bg-gray-600'} h-2.5 rounded-full right-0`} style={{ width: `${calculatePercentage(show?.comp_home.shoots, show?.comp_away.shoots)}%`}}></div>
                            </div>
                            <div className="w-1/2 bg-nav2 rounded-full h-2.5 dark:bg-gray-700">
                                <div className={` ${show?.comp_away.shoots > show?.comp_home.shoots ? 'bg-blue-600' : 'bg-gray-600'} h-2.5 rounded-full`} style={{ width: `${calculatePercentage(show?.comp_away.shoots, show?.comp_home.shoots)}%`}}></div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full h-[4vw] flex flex-col space-y-2 justify-start items-center text-white font-semibold'>
                        <div className='flex flex-row items-center justify-between w-full h-full'>
                            <span>{show?.comp_home.fouls} ({show?.comp_home.offside})</span>
                            <span>Fouls (Offside)</span>
                            <span>{show?.comp_away.fouls} ({show?.comp_away.offside})</span>
                        </div>
                        <div className='flex flex-row items-center justify-between w-full h-full space-x-3'>
                            <div className="relative w-1/2 bg-nav2 rounded-full h-2.5 dark:bg-gray-700">
                                <div className={`absolute ${show?.comp_home.fouls > show?.comp_away.fouls ? 'bg-blue-600' : 'bg-gray-600'} h-2.5 rounded-full right-0`} style={{ width: `${calculatePercentage(show?.comp_home.fouls, show?.comp_away.fouls)}%`}}></div>
                            </div>
                            <div className="w-1/2 bg-nav2 rounded-full h-2.5 dark:bg-gray-700">
                                <div className={` ${show?.comp_away.fouls > show?.comp_home.fouls ? 'bg-blue-600' : 'bg-gray-600'} h-2.5 rounded-full`} style={{ width: `${calculatePercentage(show?.comp_away.fouls, show?.comp_home.fouls)}%`}}></div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full h-[4vw] flex flex-col space-y-2 justify-start items-center text-white font-semibold'>
                        <div className='flex flex-row items-center justify-between w-full h-full'>
                            <span>{show?.comp_home.corner_kicks}</span>
                            <span>Corner Kicks</span>
                            <span>{show?.comp_away.corner_kicks}</span>
                        </div>
                        <div className='flex flex-row items-center justify-between w-full h-full space-x-3'>
                            <div className="relative w-1/2 bg-nav2 rounded-full h-2.5 dark:bg-gray-700">
                                <div className={`absolute ${show?.comp_home.corner_kicks > show?.comp_away.corner_kicks ? 'bg-blue-600' : 'bg-gray-600'} h-2.5 rounded-full right-0`} style={{ width: `${calculatePercentage(show?.comp_home.corner_kicks, show?.comp_away.corner_kicks)}%`}}></div>
                            </div>
                            <div className="w-1/2 bg-nav2 rounded-full h-2.5 dark:bg-gray-700">
                                <div className={` ${show?.comp_away.corner_kicks > show?.comp_home.corner_kicks ? 'bg-blue-600' : 'bg-gray-600'} h-2.5 rounded-full`} style={{ width: `${calculatePercentage(show?.comp_away.corner_kicks, show?.comp_home.corner_kicks)}%`}}></div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full h-[4vw] flex flex-col space-y-2 justify-start items-center text-white font-semibold'>
                        <div className='flex flex-row items-center justify-between w-full h-full'>
                            <span>{show?.comp_home.free_kicks}</span>
                            <span>Free Kicks</span>
                            <span>{show?.comp_away.free_kicks}</span>
                        </div>
                        <div className='flex flex-row items-center justify-between w-full h-full space-x-3'>
                            <div className="relative w-1/2 bg-nav2 rounded-full h-2.5 dark:bg-gray-700">
                                <div className={`absolute ${show?.comp_home.free_kicks > show?.comp_away.free_kicks ? 'bg-blue-600' : 'bg-gray-600'} h-2.5 rounded-full right-0`} style={{ width: `${calculatePercentage(show?.comp_home.free_kicks, show?.comp_away.free_kicks)}%`}}></div>
                            </div>
                            <div className="w-1/2 bg-nav2 rounded-full h-2.5 dark:bg-gray-700">
                                <div className={` ${show?.comp_away.free_kicks > show?.comp_home.free_kicks ? 'bg-blue-600' : 'bg-gray-600'} h-2.5 rounded-full`} style={{ width: `${calculatePercentage(show?.comp_away.free_kicks, show?.comp_home.free_kicks)}%`}}></div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full h-[4vw] flex flex-col space-y-2 justify-start items-center text-white font-semibold'>
                        <div className='flex flex-row items-center justify-between w-full h-full'>
                            <span>{show?.comp_home.pass}</span>
                            <span>Passes Completed</span>
                            <span>{show?.comp_away.pass}</span>
                        </div>
                        <div className='flex flex-row items-center justify-between w-full h-full space-x-3'>
                            <div className="relative w-1/2 bg-nav2 rounded-full h-2.5 dark:bg-gray-700">
                                <div className={`absolute ${show?.comp_home.pass > show?.comp_away.pass ? 'bg-blue-600' : 'bg-gray-600'} h-2.5 rounded-full right-0`} style={{ width: `${calculatePercentage(show?.comp_home.pass, show?.comp_away.pass)}%`}}></div>
                            </div>
                            <div className="w-1/2 bg-nav2 rounded-full h-2.5 dark:bg-gray-700">
                                <div className={` ${show?.comp_away.pass > show?.comp_home.pass ? 'bg-blue-600' : 'bg-gray-600'} h-2.5 rounded-full`} style={{ width: `${calculatePercentage(show?.comp_away.pass, show?.comp_home.pass)}%`}}></div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full h-[4vw] flex flex-col space-y-2 justify-start items-center text-white font-semibold'>
                        <div className='flex flex-row items-center justify-between w-full h-full'>
                            <span>{show?.comp_home.crosses}</span>
                            <span>Crosses</span>
                            <span>{show?.comp_away.crosses}</span>
                        </div>
                        <div className='flex flex-row items-center justify-between w-full h-full space-x-3'>
                            <div className="relative w-1/2 bg-nav2 rounded-full h-2.5 dark:bg-gray-700">
                                <div className={`absolute ${show?.comp_home.crosses > show?.comp_away.crosses ? 'bg-blue-600' : 'bg-gray-600'} h-2.5 rounded-full right-0`} style={{ width: `${calculatePercentage(show?.comp_home.crosses, show?.comp_away.crosses)}%`}}></div>
                            </div>
                            <div className="w-1/2 bg-nav2 rounded-full h-2.5 dark:bg-gray-700">
                                <div className={` ${show?.comp_away.crosses > show?.comp_home.crosses ? 'bg-blue-600' : 'bg-gray-600'} h-2.5 rounded-full`} style={{ width: `${calculatePercentage(show?.comp_away.crosses, show?.comp_home.crosses)}%`}}></div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full h-[4vw] flex flex-col space-y-2 justify-start items-center text-white font-semibold'>
                        <div className='flex flex-row items-center justify-between w-full h-full'>
                            <span>{show?.comp_home.interceptions}</span>
                            <span>Interceptions</span>
                            <span>{show?.comp_away.interceptions}</span>
                        </div>
                        <div className='flex flex-row items-center justify-between w-full h-full space-x-3'>
                            <div className="relative w-1/2 bg-nav2 rounded-full h-2.5 dark:bg-gray-700">
                                <div className={`absolute ${show?.comp_home.interceptions > show?.comp_away.interceptions ? 'bg-blue-600' : 'bg-gray-600'} h-2.5 rounded-full right-0`} style={{ width: `${calculatePercentage(show?.comp_home.interceptions, show?.comp_away.interceptions)}%`}}></div>
                            </div>
                            <div className="w-1/2 bg-nav2 rounded-full h-2.5 dark:bg-gray-700">
                                <div className={` ${show?.comp_away.interceptions > show?.comp_home.interceptions ? 'bg-blue-600' : 'bg-gray-600'} h-2.5 rounded-full`} style={{ width: `${calculatePercentage(show?.comp_away.interceptions, show?.comp_home.interceptions)}%`}}></div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full h-[4vw] flex flex-col space-y-2 justify-start items-center text-white font-semibold'>
                        <div className='flex flex-row items-center justify-between w-full h-full'>
                            <span>{show?.comp_home.tackles}</span>
                            <span>Tackles</span>
                            <span>{show?.comp_away.tackles}</span>
                        </div>
                        <div className='flex flex-row items-center justify-between w-full h-full space-x-3'>
                            <div className="relative w-1/2 bg-nav2 rounded-full h-2.5 dark:bg-gray-700">
                                <div className={`absolute ${show?.comp_home.tackles > show?.comp_away.tackles ? 'bg-blue-600' : 'bg-gray-600'} h-2.5 rounded-full right-0`} style={{ width: `${calculatePercentage(show?.comp_home.tackles, show?.comp_away.tackles)}%`}}></div>
                            </div>
                            <div className="w-1/2 bg-nav2 rounded-full h-2.5 dark:bg-gray-700">
                                <div className={` ${show?.comp_away.tackles > show?.comp_home.tackles ? 'bg-blue-600' : 'bg-gray-600'} h-2.5 rounded-full`} style={{ width: `${calculatePercentage(show?.comp_away.tackles, show?.comp_home.tackles)}%`}}></div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full h-[4vw] flex flex-col space-y-2 justify-start items-center text-white font-semibold'>
                        <div className='flex flex-row items-center justify-between w-full h-full'>
                            <span>{show?.comp_home.saves}</span>
                            <span>Saves</span>
                            <span>{show?.comp_away.saves}</span>
                        </div>
                        <div className='flex flex-row items-center justify-between w-full h-full space-x-3'>
                            <div className="relative w-1/2 bg-nav2 rounded-full h-2.5 dark:bg-gray-700">
                                <div className={`absolute ${show?.comp_home.saves > show?.comp_away.saves ? 'bg-blue-600' : 'bg-gray-600'} h-2.5 rounded-full right-0`} style={{ width: `${calculatePercentage(show?.comp_home.saves, show?.comp_away.saves)}%`}}></div>
                            </div>
                            <div className="w-1/2 bg-nav2 rounded-full h-2.5 dark:bg-gray-700">
                                <div className={` ${show?.comp_away.saves > show?.comp_home.saves ? 'bg-blue-600' : 'bg-gray-600'} h-2.5 rounded-full`} style={{ width: `${calculatePercentage(show?.comp_away.saves, show?.comp_home.saves)}%`}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : 
            (
                <div className='w-full h-full text-center pt-10 text-white font-bold text-[2vw]'>
                    <span>Match Statistics Not Found</span>
                </div>
            )
            }
          </div>

          {/* Sidebar Left */}
          <div className='hidden lg:w-[25%] lg:flex flex-col'>
            {/* Standings */}
            <div className='flex-col hidden w-full divide-y-2 divide-gray-700 lg:flex'>
              <h3 className='pb-2 text-lg font-bold text-white'>Standings</h3>
              <div className='w-full'>
                <StandingCard/>
              </div>
            </div>
          </div>
        </div>
    </div>
)
}

export default observer(MatchStatistics)