import { Pagination } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import MatchCard from '../../app/component/card/MatchCard';
import ScheduleCard from '../../app/component/card/ScheduleCard'
import { formatDateSchedule } from '../../app/config/enum';
import { useStore } from '../../app/stores/store'

function LeagueSchedule() {
  
  const { leagueStore } = useStore();

  const { leagueId } = useParams();

  const itemsPerPage = 5;

  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the starting and ending indexes
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Function to handle page navigation
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  useEffect(() => {
    leagueStore.getAvailableDate(Number(leagueId), "all");
    leagueStore.getSchedule(Number(leagueId), "all");
  }, [leagueId, leagueStore]);
  
  return (
    <React.Fragment>
        <div className='w-full h-full py-4 font-sans'>
            <div className='block w-full h-full divide-y-2 divide-y-reverse divide-gray-700'>
                <h3 className='mb-2 text-lg font-bold text-white'>Schedule</h3>
                <hr/>
            </div>

            {leagueStore.availableDate.slice(startIndex, endIndex).map((data, idx) => (
            <div className='w-full h-full py-4 text-white lg:px-6' key={idx}>
              <span className='font-semibold'>{data.match_type === 'qualification' ? `Knockout Stage` :data.weeks !== null || data.weeks > 0 ? `Match Day ${data.no}` : `Match Day ${data.no}`} | <span>{formatDateSchedule(data.date.toString())}</span></span>
              {leagueStore.match
                .slice()
                .sort((a, b) => Number(new Date(a.match_date)) - Number(new Date(b.match_date)))  
                .sort((a, b) => {
                  if (a.result !== null && b.result === null) {
                    return -1; // Memindahkan item dengan result tidak null ke urutan paling atas
                  } else if (a.result === null && b.result !== null) {
                    return 1; // Memindahkan item dengan result null ke urutan paling bawah
                  } else {
                    return 0; // Tidak ada perubahan urutan
                  }
                })
                .map((item, idxM) => {
                  const convertDate = item.match_date.toString().substring(0, 10);
                  if (data.date.toString() === convertDate) {
                    return (
                      <div className='flex flex-col w-full h-full mt-4 space-y-4' key={idxM}>
                        {isMobile ? <MatchCard data={item} /> : <ScheduleCard data={item} />}
                      </div>
                    );
                  } else {
                    return <div key={idxM}></div>;
                  }
              })}
            </div>
            ))}
            <div className='text-center lg:px-6 lg:text-left'>
              <Pagination defaultCurrent={1} current={currentPage} total={leagueStore.availableDate.length} pageSize={itemsPerPage} showSizeChanger={false} showLessItems onChange={(value) => handlePageChange(value)}/>
            </div>
        </div>
    </React.Fragment>
  )
}

export default observer(LeagueSchedule)