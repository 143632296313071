import { makeAutoObservable } from "mobx"
import agent from "../api/agent";
import { IClub } from "../models/club";


export default class ClubStore {

    listClub: IClub[] = [];
    club: IClub | null = null;
    playerStats: IClub | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    getClubDetail = async (idComp: number, idClub: number) => {
        try {
            const result = await agent.Club.clubById(idComp, idClub);
            this.setclub(result.data);
            return result.data;
        } catch (error) {
            throw(error)
        }
    }

    getPlayerStats = async (idComp: number, idClub: number, idPlayer: number) => {
        try {
            const result = await agent.Club.playerStats(idComp, idClub, idPlayer);
            this.setPlayerStats(result.data);
            return result.data;
        } catch (error) {
            throw(error)
        }
    }

    setclub = (data: IClub) => {
        this.club = data;
    }

    setPlayerStats = (data: IClub) => {
        this.playerStats = data;
    }
}