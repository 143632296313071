import React, { useState } from 'react';
import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { IAccountLoginValues } from '../../app/models/account';
import { useStore } from '../../app/stores/store';
import { Navigate } from 'react-router-dom';
import '../../index.css';
import InputPassword from '../../app/component/input/InputPassword';

const initialValuesLogin: IAccountLoginValues = {
    email: '-',
    password: '-',
}

const { Title } = Typography;

function LoginAccount() {

const { accountStore } = useStore();

  const [valueLogin, setValueLogin] = useState<IAccountLoginValues>(initialValuesLogin);
  const [validateEmail, setValidateEmail] = useState(false);

  const handleInputEmail = (value: string) => {
      const emailRegex = /\S+@\S+\.\S+/;
      if(emailRegex.test(value)) {
          setValidateEmail(true);
      } else {
          setValidateEmail(false);
      };
    setValueLogin(prevState => ({...prevState, email: value}));
  }

  const handleInputPassword = (value: string) => {
    setValueLogin(prevState => ({...prevState, password: value}));
  }

  const handleSubmit = () => {
    accountStore.login(valueLogin);
  };

  if (accountStore.isLoggedIn) {
    return <Navigate to="/" />;
  } 
  
  return (
    <div className='w-full h-[100wh] px-0 lg:px-40 py-0 lg:py-12 bg-bg-home bg-primary bg-no-repeat bg-cover font-sans'>
      <style>{`
        .bg-pass input {
          background: #1C1F2A;
          color: white;
        }
        `}
      </style>
      <div className='w-full h-[620px] flex flex-row bg-primary lg:rounded-xl'>
        <div className='relative hidden w-6/12 bg-bg-box-login bg-secondary bg-no-repeat bg-cover rounded-l-lg px-12 pt-20 lg:flex flex-col'>
          <div className='text-white'>
            <h1 className='font-extrabold text-[58px]'>HELLO AGAIN</h1>
            <p className='font-bold text-2xl'>
            Welcome to Indonesian Football e-League! Stay updated on the latest news and updates on Virtual Football Indonesia. Start your legendary journey with us.
            </p>
          </div>
          <div className='absolute bottom-8 text-white'>
            <p className='text-sm'>Don’t have an account?</p>
            <a className='font-bold text-sm' href='/register'>REGISTER NOW</a>
          </div>
        </div>
        <div className='w-full lg:w-6/12 pt-20 flex flex-col px-6 lg:px-16'>
          <div className='w-full'>
            <Title level={3} style={{ color: '#ffffff', fontSize: '28px', fontWeight: 'bold'}}>Login to IFel</Title>
            <p style={{ color: 'gray', marginBottom: 25}}>Don't have an account? <a href='/register' className='text-secondary'>Register</a></p>
          </div>
          <div className='w-full h-full flex flex-col mt-4 space-y-2'>   
            <div>
              <div className="relative w-full">
                  <input type="text" id="small_outlined" className="block px-2.5 pb-1.5 pt-5 w-full text-xs border-nav2 text-white bg-nav1 rounded-lg appearance-none dark:text-white peer" placeholder=" " onChange={(e) => handleInputEmail(e.target.value)} />
                  <label htmlFor="small_outlined" className="absolute text-[2.2vw] lg:text-[0.8vw] text-gray-600 font-semibold dark:text-gray-400 duration-300 transform -translate-y-1 scale-75 top-2 z-10 origin-[0] bg-nav1 dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-1 left-1">Email Address</label>
              </div>    
              {(valueLogin.email === null || valueLogin.email === '') ? <p id="outlined_error_help" className="ml-2 mt-2 text-[2.2vw] lg:text-[0.6vw] text-right text-red-600 dark:text-red-400"><span className="font-medium">Email</span> tidak boleh kosong!</p> : ((valueLogin.email !== '-') && validateEmail === false) ? <p id="outlined_error_help" className="ml-2 mt-2 text-[2.2vw] lg:text-[0.6vw] text-right text-red-600 dark:text-red-400"><span className="font-medium">Email</span> tidak sesuai format!</p> : <></>}
              </div>
              <div>
                  <InputPassword label='Password' bgColor='bg-nav1' labelColor='text-gray-600' onChange={(value) => handleInputPassword(value)} textColor='text-white'/>
                  {(valueLogin.password === null || valueLogin.password === '') && <p id="outlined_error_help" className="ml-2 mt-2 text-[2.2vw] lg:text-[0.6vw] text-right text-red-600 dark:text-red-400"><span className="font-medium">Password</span> tidak boleh kosong!</p> }
              </div>
              <div>
                <a href='/' className='text-secondary font-semibold'>Forgot Password ?</a>
              </div>
              <div className='w-full h-full text-left pt-2'>
                  {!accountStore.loadingLogin ? 
                      <button onClick={() => handleSubmit()} disabled={!valueLogin.email || valueLogin.password === '' || validateEmail === false} className={`uppercase px-10 lg:px-20 py-3 disabled:bg-gray-600 disabled:cursor-not-allowed disabled:text-gray-700 text-white font-semibold bg-secondary border-none rounded-md text-[2vw] lg:text-[0.8vw] hover:bg-nav2 hover:text-secondary transition duration-300`}>Login</button> : 
                      <button onClick={() => handleSubmit()} disabled={true} className={`uppercase loading px-10 lg:px-20 py-3 disabled:bg-secondary disabled:cursor-not-allowed disabled:text-white text-white font-semibold bg-secondary border-none rounded-md text-[2vw] lg:text-[0.8vw] hover:bg-nav2 hover:text-secondary transition duration-300`}>Please Wait</button>
                  }
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(LoginAccount);