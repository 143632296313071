import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { IAccountAddress, IAccountAddressPayload, IAccountChangePassword, IAccountInfoPayload, IAccountLoginValues, IAccountRegisterRequest, ILoginInfo } from '../models/account';
import { IPengeluaran, IPengeluaranByDate } from '../models/pengeluaran';
import { store } from '../stores/store';
import { IAvailableDatePayload, IMatchesPayload, IMatchStatsPayload } from '../models/matches';
import { ILeagueByCategoryPayload, ILeagueCategoryPayload, ILeagueFixturePayload, ILeaguePayload, IStandingsPayload } from '../models/league';
import { IProductByIdPayload, IProductCategoryPayload, IProductsPayload } from '../models/store';
import { IPayment, ISnapTokenMidtrans, ITransaction, ITransactionByIdPayload, ITransactionCreatePayload, ITransactionListPayload } from '../models/transactions';
import { IClubByIdPayload, IPlayerStatsPayload } from '../models/club';
import { INotification } from '../models/notifications';

const sleep = (delay: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay)
    })
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(config => {
    const token = store.commonStore.token;
    if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
    return config;
});

axios.interceptors.response.use(async response => {
    if (process.env.NODE_ENV === 'development') await sleep(1500);
    response = readResponseHeader(response);
    return response;
}, (error: AxiosError<any>) => {
    const { data, status, config } = error.response!;
    console.log(error.response!);

    switch (status) {
        case 400:
            if (typeof data === 'string') {
                alert('error, ' + data);
            }
            if (config.method === 'get' && data.errors.hasOwnProperty('id')) {

            }
            if (data.errors) {
                const modalStateErrors = [];
                for (const key in data.errors) {
                    if (data.errors[key]) {
                        modalStateErrors.push(' '+data.errors[key]);
                    }
                }
                alert(modalStateErrors.join());
            }
            break;
        case 401:
            if(data.error){
                alert(data.error);
            }
            break;
        case 403:
            alert('Error 403 (Forbidden)');
            break;
        case 404:
            alert('not-found');
            break;
        case 500:
            alert(error.response?.data.message);
            break;
        case 422:
            alert(error.response?.data.message);
            break;
    }
    return Promise.reject(error);
});

const readResponseHeader = (response: AxiosResponse): AxiosResponse => {
    return response;
}

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const handleBlobResponse = (response: AxiosResponse<Blob>) => {
    console.log(response);
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    const filename = response.headers["content-disposition"]!.split('"')[1];
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
}

const requests = {
    get: <T>(url: string, body?: {}) => axios.get<T>(url, body).then(responseBody),
    getFile: (url: string, body?: AxiosRequestConfig<any>) => axios.get<Blob>(url, {...body, responseType: 'blob'}).then(response => handleBlobResponse(response)),
    post: <T>(url: string, body?: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body?: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string, body?: {}) => axios.delete<T>(url, {data: body}).then(responseBody),
    postFile: <T>(url: string, body?: {}) => axios.post<T>(url, body, { headers: {"Content-Type": "multipart/form-data"} }).then(responseBody),
    putFile: <T>(url: string, body?: {}) => axios.put<T>(url, body, { headers: {"Content-Type": "multipart/form-data"} }).then(responseBody),
}

const Pengeluaran = {
    list: () => requests.get<IPengeluaran[]>('/pengeluaran'),
    create: (data:IPengeluaran) => requests.post<IPengeluaran>('/pengeluaran', data),
    listByDate: (date: string) => requests.get<IPengeluaranByDate>(`/pengeluaran/tanggal/${date}`)
}

const Account = {
    me: () => requests.get<IAccountInfoPayload>('/me'),
    login: (user: IAccountLoginValues) => requests.post<ILoginInfo>('/auth', user),
    register: (user: IAccountRegisterRequest) => requests.post<ILoginInfo>('/register', user),
    address: () => requests.get<IAccountAddressPayload>('/address'),
    createAddress: (user: IAccountAddress) => requests.post<IAccountAddress>('/address', user),
    changePassword: (password: IAccountChangePassword) => requests.post<ILoginInfo>('/chage-password', password)
}

const Matches = {
    listMatch: (competition_id: number) => requests.get<IMatchesPayload>(`/matches?competition=${competition_id}`),
    MatchStatistics: (id: number) => requests.get<IMatchStatsPayload>(`/matches/statistics/${id}`),
    listSchedule:(competition: number, match_type: string) => requests.get<IMatchesPayload>(`/matches?competition=${competition}&match_type=${match_type}`),
    availableDate: (competition: number, match_type: string) => requests.get<IAvailableDatePayload>(`/matches/available-date?competition=${competition}&match_type=${match_type}`),
}

const League = {
    listLeague: () => requests.get<ILeagueFixturePayload>('/competitions'),
    leagueById: (id: number) => requests.get<ILeaguePayload>(`/competitions/${id}`),
    listLeagueByCategory: (id: number) => requests.get<ILeagueByCategoryPayload>(`/competitions/category/${id}`),
    listCategory: () => requests.get<ILeagueCategoryPayload>('/competitions/category'),
    listStandings: (id: number) => requests.get<IStandingsPayload>(`/competitions/${id}/classement`),
    latestLeagueStandings: () => requests.get<IStandingsPayload>(`/competitions/latest-standings`),
}

const Store = {
    listProducts: (category: string, sort:string) => requests.get<IProductsPayload>(`/products?category=${category}&sort=${sort}`),
    product: (id: number) => requests.get<IProductByIdPayload>(`/product/${id}`),
    listCategory: () => requests.get<IProductCategoryPayload>('/category-products'),
}

const Transaction = {
    addTransaction: (data: ITransaction) => requests.post<ITransactionCreatePayload>('/transaction', data),
    listTransactionsByUser: (page: number) => requests.get<ITransactionListPayload>(`/transaction-list?page=${page}`),
    transactionById: (id: string | undefined) => requests.get<ITransactionByIdPayload>(`/transaction/${id}`),
    payment: (data: IPayment) => requests.post<ISnapTokenMidtrans>('/pembayaran', data)
}

const Notification = {
    listNotification: () => requests.get<INotification[]>('/notifications'),
    readNotification: () => requests.post<void>('/notifications/read')
}

const Club = {
    clubById: (idClub: number, idCompetition: number) => requests.get<IClubByIdPayload>(`/competitions/${idCompetition}/club/${idClub}`),
    playerStats: (idClub: number, idCompetition: number, idPlayer: number) => requests.get<IPlayerStatsPayload>(`/competitions/${idCompetition}/club/${idClub}/player/${idPlayer}`),
}

const agent = {
    Pengeluaran,
    Account,
    Matches,
    League,
    Store,
    Transaction,
    Notification,
    Club
}

export default agent;